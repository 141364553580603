import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../contexts/process/ProcessContext';
import NumberPad from '../ui-elements/NumberPad';
import StatusBarButton from './StatusBarButton';
import { number } from 'prop-types';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const WallSelector = ({ forwardTo }) => {
  const navigate = useNavigate();

    const { continueProcess, activeProcessId, processFinished, notifyError } = useContext(ProcessContext);
  
    const [display, setDisplay] = useState({
        prefix: 'w',
        value: ''
    });
  
    const proceedToWallSelection = async () => {
      if (parseInt(display.value, 10) > parseInt(import.meta.env.VITE_MAX_WALL_NUMBER, 10) || parseInt(display.value, 10) < 1) {
        notifyError(`You tried to enter an invalid wall. Please select a wall between 1 and ${import.meta.env.VITE_MAX_WALL_NUMBER}`);        
        return;
      }
      try {
        await continueProcess(activeProcessId, display.prefix + display.value);
      navigate(forwardTo);
      } catch (error) {
        notifyError(<>We have encountered a problem while continueing the Process. Please contact the support team {HELP_DESK_LINK}.</>)
      }
    };
  
    const setValue = (value) => {
        setDisplay(prev => ({ ...prev, value }))
    }
  
    return (
      <Fragment>
        <NumberPad prefix={display.prefix} value={display.value} setValue={setValue} />
        {display.value && (
          <StatusBarButton
            type='inline'
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            statusSlot={5}
            clickHandler={proceedToWallSelection}
          />
        )}
      </Fragment>
    );
}

export default WallSelector
