import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import Button from '../layout/Button';
import { TemplateUpload } from './TemplateUpload';
import Loading from '../ui-elements/Loading';
import useConfirm from '../confirmation/useConfirm';

const TemplateAddingCard = ({ template, editOn, createTemplateHandler, cancelHandler }) => {

  const [loading, setLoading] = useState(false);
  const { confirm, ConfirmDialog } = useConfirm();

  const initialState = {
    name: {
      value: template?.name ?? '',
      touched: false,
      validate: function (text) {
        return text?.length >= 3;
      },
      isValid: !!template?.name,
      placeholder: 'Template name ...',
    },
    description: {
      value: template?.description ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: 'Template description ...',
    },
    template: {
      value: template?.template ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
    },
  };

  const [templateState, setTemplateState] = useState(initialState);

  const updateTemplate = () => {
    let body = template;
  };
  const deleteTemplate = () => console.log('Delete template.');

  const isStateValid = Object.values(templateState)
    .map((entry) => entry.isValid)
    .every((item) => !!item);
  useEffect(() => { }, [templateState]);
  useEffect(() => {
    if (template) {
      const templateStateInit = Object.entries(template).reduce((acc, [key, value]) => {
        if (key in templateState === false) return acc;

        return {
          ...acc,
          [key]: { ...acc[key], value, isValid: acc[key]?.validate(value) },
        };
      }, initialState);

      setTemplateState(templateStateInit);
    }
  }, [template]);

  const onChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setTemplateState((prev) => ({
      ...prev,
      [key]: {
        ...templateState[key],
        value,
        isValid: templateState[key].validate(value),
        touched: true,
      },
    }));
  };

  const templateCreate = async () => {
    setLoading(true);
    let newTemplate = createRequestBody(templateState);
    await createTemplateHandler(newTemplate);
    setLoading(false);
  };

  const createRequestBody = () => {
    const updatedTemplateProperties = Object.entries(templateState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});
    const updatedTemplate = {
      ...template,
      ...updatedTemplateProperties,
    };

    return updatedTemplate;
  };

  const handleDelete = async () => {
    let result = await confirm({
      title: (
        <>
          <strong>DELETE SEED TYPE</strong>
        </>
      ),
      message: 'Are you sure?',
      confirmText: 'DELETE',
      confirmColor: 'success',
      cancelColor: 'muted',
      centered: true,
    });

    if (result) {
      await deleteTemplate(template.id);
    }
  };

  const templateChangeHandler = (data, fileName) => {
    setTemplateState((prev) => ({
      ...prev,
      fileName: {
        value: fileName,
        isValid: true,
        touched: true,
      },
      template: {
        ...template,
        value: data,
        isValid: true,
        touched: true,
      },
    }));
  };

  const templateResetHandler = () => {
    setTemplateState((prev) => ({
      ...prev,
      template: {
        ...template,
        value: null,
        isValid: true,
        touched: true,
      },
    }));
  };

  return (loading ? <Loading fullScreen={true} /> :
    <>
      <ConfirmDialog />
      <Card className='seed-card'>
        <CardHeader>
          <input
            className={
              templateState.name.isValid || !templateState.name.touched
                ? 'h4-input seed-property-input-field header-input'
                : 'h4-input seed-property-input-field header-input invalid-field'
            }
            type='text'
            minLength='3'
            value={templateState.name.value}
            onChange={onChange}
            name='name'
            id='name'
            placeholder={templateState.name.placeholder}
            disabled={false}
          />
          {!templateState.name.isValid && templateState.name.touched && <p>Name must have 3 characters or more.</p>}
        </CardHeader>
        <CardBody className='seed-card-body'>
          <Fragment>
            <textarea
              // type='textarea'
              rows='3'
              // wrap='hard'
              name='description'
              className={
                templateState.description.isValid || !templateState.description.touched
                  ? 'seed-property-input-field italic header-input'
                  : 'seed-property-input-field italic header-input invalid-field'
              }
              minLength='3'
              value={templateState.description.value}
              placeholder={templateState.description.placeholder}
              onChange={onChange}
              disabled={false}
            // style={{ whiteSpace: 'pre-wrap' }}
            />
            <TemplateUpload
              changeHandler={templateChangeHandler}
              resetHandler={templateResetHandler}
              labelText='Upload Template'
              originalReuploadLabelText={`New template file: <fileName>\nClick to replace with a new file.`}
              originalTemplate={template}
              updatedTemplate={templateState}
              textSize={templateState?.fileName?.value ? '0.8em' : '1.2em'}
            />
          </Fragment>
        </CardBody>

        <div className='card-button seed-card-footer'>
          <Fragment>
            <Button className='seed-card-btn' label='Cancel' clickHandler={cancelHandler} />
            <Button
              className='seed-card-btn'
              label='Create'
              clickHandler={templateCreate}
              disabled={!isStateValid || !templateState?.fileName?.value}
            />
          </Fragment>
        </div>
      </Card>
    </>
  );
};

export default TemplateAddingCard;
