import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthContext from '../../../contexts/auth/AuthContext';
import ContainersApiService from '../../../services/apiServices/ContainersApiService';
import { Card, Modal, ModalHeader, ModalBody } from 'reactstrap';
import UIContext from '../../../contexts/ui/UIContext';
import StatusBarButton from '../../layout/StatusBarButton';
import ProcessContext from '../../../contexts/process/ProcessContext';
import ContainerCard from './Card';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
import useConfirm from '../../confirmation/useConfirm';

const ContainerManagement = () => {
  const navigate = useNavigate();
  const { setInfo, addNotification } = useContext(UIContext);
  const {
    currentTenant,
    currentContainer,
    accessibleContainers,
    loadAndSetAccessibleContainersAsync
  } = useContext(AuthContext);
  const { notifyWarning } = useContext(ProcessContext);

  const [loading, setLoading] = useState(false);
  const { confirm, ConfirmDialog } = useConfirm();


  useEffect(() => {
    setInfo('Container Management');
  }, []);

  const clickHandler = () => {
    navigate('/management/containers/create');
  };

  const handleDeleteContainer = async (id) => {
    let result = await confirm({
      title: (
        <>
          <strong>DELETE CONTAINER</strong>
        </>
      ),
      message:
        'When you delete a container all related data (plants, produce, reports, pictures, weights and many more) will be deleted forever. This operation can not be undone.',
      confirmText: 'Are you sure?',
      confirmColor: 'success',
      cancelColor: 'muted',
      centered: true,
    });

    if (result) {
      try {
        setLoading(true);
        await ContainersApiService.deleteContainer(currentTenant?.tenantId, id);
        addNotification('Successfully deleted container.', 'success');
        loadAndSetAccessibleContainersAsync(currentTenant);
      } catch (error) {
        if (error.message === 'Not found.') {
          notifyWarning(<>We have encountered an issue while deleting the container. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>, 'error');
        } else {
          notifyWarning(<>We have encountered an issue on our side while deleting the container. Please contact support team {HELP_DESK_LINK}.</>, 'error');
        }
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      <div className='container-management-container'>
        <div className='container-list-overview'>
          <Card className='create-container-card' onClick={clickHandler}>
            <h1 className='add-seed-symbol'>
              <i className='fas fa-plus'></i>
            </h1>
          </Card>
          {accessibleContainers &&
            accessibleContainers.sort(function (a,b){
              let x = a.name.toUpperCase(),
                  y = b.name.toUpperCase();
              return x == y ? 0 : x > y ? 1 : -1; 
            }).map((container) => (
              <ContainerCard
                key={container.id}
                container={container}
                deleteHandler={handleDeleteContainer}
              />
            ))}
        </div>
      </div>
      <StatusBarButton
        icon='fair fai-circle-check'
        label='Done'
        type='inline'
        clickHandler={() => navigate('/')}
        statusSlot={3}
      />
      <Modal isOpen={loading}>
        <ModalHeader>Container deletion in progress...</ModalHeader>
        <ModalBody>
          <p>Deleting the container may take up to several minutes. Please don't navigate away or close the app.</p>
          <i className='fas fa-cog fa-spin'></i>
        </ModalBody>
      </Modal>
      <ConfirmDialog />
    </>
  );
};

export default ContainerManagement;
