import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const useConfirm = () => {
  const [config, setConfig] = useState(null);

  const confirm = (options) => {
    return new Promise((resolve) => {
      setConfig({
        ...options,
        onConfirm: () => {
          setConfig(null);
          resolve(true);
        },
        onCancel: () => {
          setConfig(null);
          resolve(false);
        },
      });
    });
  };

  const ConfirmDialog = () => (
    <div >
      <Modal className='reactstrap-confirm' isOpen={!!config} toggle={config?.onCancel} centered={config?.centered || false}>
        {config?.title && (
          <ModalHeader toggle={config.onCancel}>
            {config.title}
          </ModalHeader>
        )}
        <ModalBody>{config?.message}</ModalBody>
        <ModalFooter>
          <Button color={config?.confirmColor || 'primary'} onClick={config?.onConfirm}>
            {config?.confirmText || 'Confirm'}
          </Button>
          <Button color={config?.cancelColor || 'secondary'} onClick={config?.onCancel}>
            {config?.cancelText || 'Cancel'}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );

  return { confirm, ConfirmDialog };
};

export default useConfirm;
