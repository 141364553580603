
import React from 'react';;
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';

import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import UIState from './contexts/ui/UIState';
import AuthProvider from './contexts/auth/AuthProvider';
import ProcessProvider from './contexts/process/ProcessState';
import TicketsProvider from './contexts/tickets/TicketsProvider';
import SessionInformationProvider from './contexts/session-information/SessionInformationProvider';
import './index.scss';
//import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');
console.log("FarmerApp is starting!")

/**
 * https://a26058b5-fe3d-4c2e-9066-63d5037bb94d.auth.eu-central-1.amazoncognito.com/oauth2/authorize?redirect_uri=https%3A%2F%2Flocalhost%3A44316&response_type=token&client_id=28n378ligdqv03vkpg284ic8qa&identity_provider=COGNITO&scope=phone%20email%20profile%20openid%20aws.cognito.signin.user.admin&state=4lxGjCxiCBFEdxc2OOMm2buRIB4hGsTa
 */

const router = createBrowserRouter([
    {
        path: '/*',
        element: <App />
    }
],
    {
        future: {
            v7_startTransition: true,
            v7_relativeSplatPath: true,
            v7_fetcherPersist: true,
            v7_normalizeFormMethod: true,
            v7_partialHydration: true,
            v7_skipActionErrorRevalidation: true,
        },
    })

console.log(`Version: ${import.meta.env.VITE_VERSION_TEXT}`)

ReactDOM.createRoot(rootElement).render(
    <UIState>
        <SessionInformationProvider>
            <AuthProvider>
                <ProcessProvider>
                    <TicketsProvider>
                        <RouterProvider router={router} />
                    </TicketsProvider>
                </ProcessProvider>
            </AuthProvider>
        </SessionInformationProvider>
    </UIState>
);