import { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import { CardBody, CardHeader } from 'reactstrap';
import CardReactStrap from 'reactstrap/lib/Card';
import { useNavigate } from 'react-router-dom';
import Loading from '../../ui-elements/Loading';
import Button from '../../layout/Button';
import { OPERATOR, TENANT_ADMIN, MARKETING } from '../../../constants/roles/roles';
import AuthContext from '../../../contexts/auth/AuthContext';
import ProduceTypeContext from '../../../contexts/management/produceTypes/ProduceTypeContext';
import ConfirmEditModal from './ConfirmEditModal';
import useFarmerAppPolicy from '../../../customHooks/useFarmerAppPolicy';
import ProcessContext from '../../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
import useConfirm from '../../confirmation/useConfirm';
const Card = ({ produceType }) => {

    const { accessibleSeedTypes, currentTenant, currentContainer } = useContext(AuthContext);
    const { notifyError, notifySuccess } = useContext(ProcessContext);
    const { allProduceTypes, deleteProduceType, getManyProduceTypes, loading } = useContext(ProduceTypeContext);
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
    const [seedType, setSeedType] = useState(null);
    const navigate = useNavigate();
    const tenantAdminPolicy = useFarmerAppPolicy([TENANT_ADMIN]);
    const operatorPolicy = useFarmerAppPolicy([TENANT_ADMIN, OPERATOR, MARKETING]);
    const { confirm, ConfirmDialog } = useConfirm();

    useEffect(() => {
        const loadSeedType = async () => {
            const seedType = accessibleSeedTypes.find((seedType) => seedType?.id === produceType?.seedTypeId);
           setSeedType(seedType);
        }
        
        loadSeedType();
    }, []);

    const getSeedTypeNameAndNumber = () => {       
        return seedType?.name + ' (Nº ' + seedType?.number + ')';
    };

    const handleDelete = async () => {
        let result = await confirm({
            title: (
                <>
                    <strong>DELETE PRODUCE TYPE</strong>
                </>
            ),
            message: (
                <>
                    Are you sure?<br /><br />
                    Deleting this produce type will affect ALL produces, including those that have already left the container or are in the hands of consumers.<br /><br />
                    Do you really want to continue?
                </>
            ),
            confirmText: 'DELETE',
            confirmColor: 'success',
            cancelColor: 'muted',
            centered: true,
        });

        if (result) {
            try {
                await deleteProduceType(produceType.id);
                notifySuccess('Successfully deleted produce type.');
                await getManyProduceTypes();
            } catch (error) {
                console.error("Error while deleting produce type. ", error)
                if (error.internalStatusCode === 404) {
                    notifyError(<>We have encountered an issue while deleting the produce type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                }
                else {
                    notifyError(<>We have encountered an issue on our side while deleting the produce type. Please contact the support team {HELP_DESK_LINK}.</>);
                }
            }
        };
    };

    const handleDetail = () => {
        navigate(`/management/produce-types/${produceType.id}/details`);
    }

    const handleEdit = async () => {
        setShowConfirmEditModal(true);
    };

    const onCancelConfirmEdit = () => {
        setShowConfirmEditModal(false);
    };

    const confirmEdit = useCallback(() => {
        navigate(`/management/produce-types/${produceType.id}/edit`);
    });

    return allProduceTypes.length < 1 || accessibleSeedTypes < 1 || loading || !seedType ? (
        <Loading fullScreen={true} />
    ) : (<>
        <ConfirmEditModal show={showConfirmEditModal} onCancel={onCancelConfirmEdit} onConfirm={confirmEdit} />
        <CardReactStrap className='produce-type-card'>
            <CardHeader className='produce-type-card-header' onClick={handleDetail}>
                <Fragment>
                    <div className={'produce-type-card-header-upper'}>
                        <h4>{produceType?.marketingName}</h4>
                        <h4>Nº {produceType?.number}</h4>
                    </div>
                    <p className='produce-type-property-paragraph italic' >
                        {produceType?.classification}
                    </p>
                </Fragment>
            </CardHeader>
            <CardBody className='produce-type-card-body' onClick={handleDetail}>
                <label className='input-label' htmlFor='seedName'>
                    Seed Type
                </label>
                <br />
                <p className='produce-type-property-paragraph italic' >
                    {getSeedTypeNameAndNumber()}
                </p>
            </CardBody>
            <div className='card-button produce-type-card-footer'>
                <Button className='produce-type-card-btn' label={'Delete'} clickHandler={handleDelete} disabled={tenantAdminPolicy.isForbidden()} />
                <Button className='produce-type-card-btn' label={'Edit'} clickHandler={handleEdit} disabled={operatorPolicy.isForbidden()} />
            </div>
        </CardReactStrap>
        <ConfirmDialog />
    </>
    );
};

export default Card;