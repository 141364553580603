import axios from "axios";
import {Auth} from 'aws-amplify';

const axiosClient = axios.create({
    baseURL: `${import.meta.env.VITE_CALLBACK_URL}`,
    headers:{
        "Content-Type": "application/json",
    }
})

axiosClient.interceptors.request.use(
    async (config) => {
      const session = await Auth.currentSession();
      const accessToken = session.getAccessToken();
  
      if (import.meta.env.VITE_ENV === 'development') {
        config.baseURL = import.meta.env.VITE_FARMER_API_URL;
      }
  
      if (!accessToken) throw new Error('Unauthorized');
  
      config.headers['Authorization'] = `Bearer ${accessToken.getJwtToken()}`;
      return config;
    },
    (error) => {
      console.error("ERROR FROM HTTPAGENT:" ,error);
      delete axios.defaults.headers['Authorization'];
  
      Promise.reject('No access token available');
    }
);

export default axiosClient;