import React, { Fragment, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import StatusBarButton from '../../layout/StatusBarButton';
import NumberPad from '../../ui-elements/NumberPad';
import Loading from '../../ui-elements/Loading';
import UIContext from '../../../contexts/ui/UIContext';
import BackButton from '../../layout/BackButton';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';


const PanelSelector = ({ forwardTo, backwardTo }) => {
    const { activeProcessId, continueProcess, notifyError } = useContext(ProcessContext);
    const { loading } = useContext(UIContext);

    const [display, setDisplay] = useState({
        prefix: 'p',
        value: ''
    });

    const navigate = useNavigate(); 

    const proceed = useCallback(async () => {
      if (parseInt(display.value) > parseInt(import.meta.env.VITE_MAX_PANEL_NUMBER) || parseInt(display.value) < 1) {
        notifyError(`You tried to enter an invalid Panel. Please select a panel between 1 and ${import.meta.env.VITE_MAX_PANEL_NUMBER}`)
        return;
      }
        try {
          await continueProcess(activeProcessId, `${display.prefix}${display.value}`);
          navigate(forwardTo);
        } catch (error) {
          notifyError(<>We have encountered a problem while continueing the Process. Please contact the support team {HELP_DESK_LINK}.</>)
          console.error(error);
        }
      }, [activeProcessId, continueProcess, display, forwardTo, navigate]);

      const setValue = (value) => {
        setDisplay(prev => ({ ...prev, value }))
    };
    
    return (loading ? 
        <Loading fullScreen /> :
        <Fragment>
            <NumberPad prefix={display.prefix} value={display.value} setValue={setValue} />
            {display.value && <StatusBarButton label='Continue' icon='fas fa-chevron-right' reversed={true} clickHandler={proceed} statusSlot={5} />}
            <BackButton disabled={false} navigateTo={backwardTo}/>
        </Fragment>
    )
}

export default PanelSelector
