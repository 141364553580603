import React from 'react';

const HarvestingProcedureCard = ({ className, text, onClickCallback }) => {
    console.log("Class name: ", className)
    return (
        <div
            className={`harvesting-procedure-card-body`}
            onClick={onClickCallback}
        >
            <span className={`fair-inline ${className}`}></span>
            <div className='harvesting-procedure-card-text'>
            {text}
            </div>
        </div>
    );
};

export default HarvestingProcedureCard;
