import React, { useEffect, useReducer, useContext } from 'react';
import AuthContext from './AuthContext';
import AuthReducer from './AuthReducer';
import SeedTypesApiService from '../../services/apiServices/SeedTypesApiService';

import {
  RESET,
  SET_CURRENT_USER,
  SET_CURRENT_CONTAINER,
  SET_CURRENT_TENANT,
  SET_ACCESSIBLE_CONTAINERS,
  SET_ACCESSIBLE_TEMPLATES,
  SET_ACCESSIBLE_SEEDTYPES,
  SET_LAST_SELECTED_CONTAINER,
  SET_LAST_SELECTED_TENANT,
  UPDATE_USER_INFORMATION,
  UPDATE_USER_CONTEXT,
  UPDATE_ACCESSIBLE_TENANTS,
  SET_LOADING_SEEDTYPES
} from '../types/contextTypes';
import { TENANTS_BASE_URL, USERS_BASE_URL, CONTAINERS_URL } from '../../constants/routes/api';
import { Auth } from 'aws-amplify';
import AWS from 'aws-sdk';
import { awsconfig } from '../../aws-config/awsconfig';
import { HttpAgent } from '../../utility/HttpAgent';
import { GLOBAL_ADMIN } from '../../constants/roles/roles';
import jwtDecode from 'jwt-decode';
import SeedVariantTypesApiService from '../../services/apiServices/SeedVariantTypesApiService';
import SessionInformationContext from '../session-information/SessionInformationContext';

export default (props) => {
  const initialState = {
    userInformation: null,
    currentTenant: null,
    currentContainer: null,
    currentRoles: null,
    isAuthenticated: false,
    loading: false,
    accessibleTenants: [],
    accessibleContainers: [],
    accessibleSeedTypes: [],
    accessibleTemplates: [],
    sessionInformation: null,
    isGlobalAdmin: false,
    seedTypesLoading: false
  };

  const { sessionInformation, setSessionInformation, fetchSessionInformation } = useContext(SessionInformationContext); 

  const auth = async () => {
    await Auth.currentAuthenticatedUser()
      .then(async (data) => {

        if (!data) {
          await Auth.signOut();
        }

        let newUserState = initialState;
        const { username, signInUserSession } = data;
        // const attributes = getUserAttributes(data);
        if (!signInUserSession) {
          throw new Error('No sign in session detected.');
        }
        // Get user's necessary personal information from cognito.
        const idToken = signInUserSession.getIdToken().getJwtToken();
        const attributes = jwtDecode(idToken);

        // Collect information from cognito.
        const cognitoUser = {
          userId: username,
          firstName: attributes.given_name,
          lastName: attributes.family_name,
          email: attributes.email,
          userPoolId: data.pool.userPoolId,
        };
        newUserState.userInformation = cognitoUser;

        //   Which tenants can the user access? What were his session settings? Getting this parallel.
        let accessibleTenantsTask = loadAccessibleTenantsAsync(cognitoUser).then((accessibleTenants) => {
          newUserState.accessibleTenants = accessibleTenants;
        });

        await Promise.all([accessibleTenantsTask]);
        const dbUserResponse = await HttpAgent.get(`${USERS_BASE_URL}/${cognitoUser.userId}`);
        const dbUser = dbUserResponse?.data;
        const userRoleResponse = await HttpAgent.get(`${USERS_BASE_URL}/${dbUser.id}/role`);

        // Now we can set the current tenant.
        const currentSessionInformation = await fetchSessionInformation(username);

        if (currentSessionInformation?.lastSelectedTenantId) {
          newUserState.currentTenant = newUserState.accessibleTenants.find(
            (t) => t.tenantId === currentSessionInformation.lastSelectedTenantId
          );
        }

        if (!newUserState.currentTenant) {
          newUserState.currentTenant = newUserState.accessibleTenants[0];
        }

        // Now that we have the current tenant, we can load the accessible containers and accessible label printer templates and set the current container.
        newUserState.accessibleContainers = await _loadAccessibleContainersAsync(newUserState?.currentTenant?.tenantId);
        newUserState.accessibleTemplates = await loadAccessibleTemplatesAsync(newUserState?.currentTenant);
        if (currentSessionInformation?.lastSelectedTenantId && currentSessionInformation?.lastSelectedContainerId) {
          newUserState.currentContainer = newUserState.accessibleContainers?.find(
            (c) => c.id === currentSessionInformation.lastSelectedContainerId
          );
        }
        if (!newUserState.currentContainer && newUserState.accessibleContainers.length > 0) {
          newUserState.currentContainer = newUserState.accessibleContainers[0];
        }

        newUserState.accessibleSeedTypes = await loadAccessibleSeedTypesAsync(newUserState?.currentTenant, newUserState.currentContainer, true, 'seedVariantTypes');

        // Now that we know the current tenant and current container, we can fetch and set the current role.
        newUserState.currentRoles = (await _loadUserRoleForTenant(
          newUserState.currentTenant.tenantId,
          cognitoUser.userId
        ));
        
        if (!currentSessionInformation) {
          const newSessionInformation = {
            lastSelectedTenantId: newUserState.currentTenant?.tenantId,
            lastSelectedContainerId: newUserState.currentContainer?.id
          };
          
          setSessionInformation(newSessionInformation);
        }
        // If the current user is global admin, we want to set the user state accordingly.
        newUserState.isGlobalAdmin = userRoleResponse?.data?.role === GLOBAL_ADMIN;
        newUserState.isAuthenticated = true;

        dispatch({
          type: UPDATE_USER_CONTEXT,
          payload: newUserState,
        });
      })
      .catch((err) => {
        console.error('Error caught In Auth ', err);
        reset();
      });
  };
  const [state, dispatch] = useReducer(AuthReducer, initialState);

  const updateGlobalAdminRole = async (userId, newGlobalAdminRole) => {
    let body = {
      data: {
        role: newGlobalAdminRole ? 'globalAdmin' : 'none',
      },
    };

    await HttpAgent.put(`${USERS_BASE_URL}/${userId}/role`, body);
  };

  const _loadUserRoleForTenant = async (tenantId, userId) => {
    if (!tenantId || !userId) {
      console.warn('No tenant id or user id provided while getting user role!');
    }
    let result = await HttpAgent.get(`${TENANTS_BASE_URL}/${tenantId}/users/${userId}/role`);
    return result.data.roles;
  };

  const _loadAccessibleContainersAsync = async (tenantId) => {
    let containers = await HttpAgent.get(`${TENANTS_BASE_URL}/${tenantId}/containers`);
    return containers.data;
  };

  const _getTenantByTenantIdAsync = async (tenantId) => {
    if (!tenantId) {
      console.warn('No tenant id provided!');
      return;
    }
    let result = await HttpAgent.get(TENANTS_BASE_URL + `/${tenantId}`);

    return {
      tenantId: result?.data?.tenantId,
      name: result?.data?.name,
    };
  };

  const _getContainerByContainerIdAsync = async (containerId) => {
    if (!containerId) {
      return;
    }
    let result = await HttpAgent.get(CONTAINERS_URL + `/${containerId}`);
    return {
      id: result?.data?.id,
      name: result?.data?.name,
    };
  };

  const _setLastSelectedContainer = (container) => {
    dispatch({
      type: SET_LAST_SELECTED_CONTAINER,
      payload: container,
    });
  };

  const _setLastSelectedTenant = (tenant) => {
    dispatch({
      type: SET_LAST_SELECTED_TENANT,
      payload: tenant,
    });
  };

  const _loadSessionInformationAsync = async (user) => {
    if (!user) {
      throw new Error('Argument Null Exception: User was null in load user context call.');
    }

    let sessionInformationResponse = await HttpAgent.get(USERS_BASE_URL + `/${user.userId}/sessionInformation`);
    return sessionInformationResponse?.data;
  };

  const loadAccessibleTenantsAsync = async (user) => {
    if (!user) {
      return;
    }
    const accessibleTenantIdsResponse = await HttpAgent.get(USERS_BASE_URL + `/${user.userId}/tenants`);
    const accessibleTenantIds = accessibleTenantIdsResponse?.data;
    if (accessibleTenantIds.length === 0) {
      return Promise.resolve(accessibleTenantIds);
    }
    let asyncCallsForTenants = new Array();
    let tenants = new Array();
    accessibleTenantIds.forEach((tenantId) => {
      asyncCallsForTenants.push(
        HttpAgent.get(TENANTS_BASE_URL + `/${tenantId}`).then((result) => {
          tenants.push(result.data);
        })
      );
    });

    var allTenants = await Promise.all(asyncCallsForTenants)
      .then((listOfTenantResponses) => {

        return tenants;
      })
      .catch((err) => {

        throw new Error(err.message);
      });

    return allTenants.sort((a, b) => a.name < b.name);
  };

  const reloadAccessibleTenants = async (user) => {
    var tenants = await loadAccessibleTenantsAsync(user)
      .then((listOfTenants) => {
        dispatch({
          type: UPDATE_ACCESSIBLE_TENANTS,
          payload: listOfTenants,
        });
        return listOfTenants;
      });

    return tenants;
  };

  const loadTenantUsers = async (tenantId) => {
    if (!tenantId) {
      return;
    }
    //1 Get all users ids for tenant.
    const userIdsInTenant = await _getallTenantUsers(tenantId);

    //2 Load the user for each id;
    const usersWithRoles = [];
    for (const userId of userIdsInTenant) {
      let tenantUserResult = await HttpAgent.get(`${USERS_BASE_URL}/${userId}`);

      //2b ...and get the user's role.
      let roleForThisUser = await _loadUserRoleForTenant(tenantId, tenantUserResult.data.userId);
      tenantUserResult.data.role = roleForThisUser;
      usersWithRoles.push(tenantUserResult.data);
    }

    return usersWithRoles;
  };

  const _getallTenantUsers = async (tenantId) => {
    try {
      let url = `${TENANTS_BASE_URL}/${tenantId}/users`;
      let result = await HttpAgent.get(url);
      return result.data;
    } catch (err) {
      console.error('Error while getting all tenant users from api.', err);
    }
  };

  const loadAccessibleTemplatesAsync = async (tenant) => {
    if (!tenant.tenantId) {
      return [];
    }

    try {
      const result = await HttpAgent.get('api/v1/tenants' + `/${tenant?.tenantId}/LabelPrinterTemplates`);
      return result?.data?.labelPrinterTemplates;
    } catch (error) {
      console.error('Error while loading accessible containers for tenant ' + `${tenant?.tenantId}`);
    }
  };

  const loadAndSetAccessibleTemplatesAsync = async (tenant) => {
    const templates = await loadAccessibleTemplatesAsync(tenant);
    dispatch({
      type: SET_ACCESSIBLE_TEMPLATES,
      payload: templates,
    });
  };

  const loadAccessibleSeedTypesAsync = async (tenant, container, includeOutdated = true, include = null) => {
    dispatch({
      type: SET_LOADING_SEEDTYPES,
      payload: true
    });

    if (!container?.id || !tenant?.tenantId) {
      return [];
    }
    try {
      const response = await SeedTypesApiService.getAllSeedTypes(tenant?.tenantId, container.id, includeOutdated, include);
      const seedTypes = response.data.data;
      return seedTypes;
    } catch (error) {
      console.error(error);
    }
  };

  // const loadAndSetAccessibleSeedTypesAsync = async (tenant, container, includeOutdated = false) => {
  //   const seedTypes = await loadAccessibleSeedTypesAsync(tenant, container);
  //   dispatch({
  //     type: SET_ACCESSIBLE_SEEDTYPES,
  //     payload: seedTypes,
  //   });
  // };

  const loadAndSetAccessibleSeedTypesAsync = async (tenant, container, includeOutdated = true, include = null) => {
    const seedTypes = await loadAccessibleSeedTypesAsync(tenant, container, includeOutdated, include);
    dispatch({
      type: SET_ACCESSIBLE_SEEDTYPES,
      payload: seedTypes,
    });
  };

  const updateContainer = async (container) => {
    try {
      const body = {
        data: {
          id: container?.id,
          name: container?.name,
          description: container?.description,
          farmHandName: (!container?.farmHandName || container.farmHandName === '') ? null : container.farmHandName,
          cameras: container?.cameras,
          street: container?.location?.street,
          number: container?.location?.number,
          postalCode: container?.location?.postalCode,
          city: container?.location?.city,
          country: container?.location?.country,
          latitude: container?.location?.latitude,
          longitude: container?.location?.longitude
        },
      };

      const result = await HttpAgent.put(
        `api/v1/tenants/${state.currentTenant?.tenantId}/Containers/${container.id}`,
        JSON.stringify(body)
      );
    } catch (e) {
      throw new Error(e);
    } finally {
      await loadAndSetAccessibleContainersAsync();
    }
  };

  const loadAccessibleContainersAsync = async (tenant) => {
    if (!tenant?.tenantId) {
      return [];
    }

    try {
      const result = await HttpAgent.get('api/v1/tenants' + `/${tenant?.tenantId}/Containers`);
      return result?.data;
    } catch (error) {
      console.error('Error while loading accessible containers for tenant ' + `${tenant?.tenantId}`);
    }
  };

  const loadAndSetAccessibleContainersAsync = async (tenant) => {
    if (!tenant?.tenantId) {
      return [];
    }
    const newAccessibleContainers = await loadAccessibleContainersAsync(tenant);
    dispatch({
      type: SET_ACCESSIBLE_CONTAINERS,
      payload: newAccessibleContainers,
    });
  };

  const setAccessibleContainersAndCurrentContainer = async (tenant) => {
    const result = await HttpAgent.get('api/v1/tenants' + `/${tenant?.tenantId}/Containers`);
    return result.data;
  };

  const setAccessibleContainers = async (tenant) => {
    const result = await HttpAgent.get('api/v1/tenants' + `/${tenant?.tenantId}/Containers`);

    dispatch({
      type: SET_ACCESSIBLE_CONTAINERS,
      payload: result.data,
    });

    return result.data;
  };

  const setCurrentUser = (user) => {
    dispatch({
      type: SET_CURRENT_USER,
      payload: user,
    });
  };

  const setCurrentTenant = async (newTenant) => {
    if (!newTenant?.tenantId) {
      return;
    }

    const newRoles = await getCurrentRoles(newTenant);
    const newContainers = await _loadAccessibleContainersAsync(newTenant.tenantId);
    const newCurrentContainer = newContainers.length < 1 ? null : newContainers[0];
    
    const newSessionInformation = { ...sessionInformation, lastSelectedTenantId: newTenant.tenantId, lastSelectedContainerId: newCurrentContainer == null ? null : newCurrentContainer.id };
    
    setSessionInformation(newSessionInformation);

    const newSeedTypes = await loadAccessibleSeedTypesAsync(newTenant, newCurrentContainer, true, 'seedVariantTypes');
    const newTemplates = await loadAccessibleTemplatesAsync(newTenant);
    if (JSON.stringify(state.currentTenant) !== JSON.stringify(newTenant)) {
      dispatch({
          type: SET_CURRENT_TENANT,
          payload: {
              newContainers: newContainers,
              newCurrentContainer: newCurrentContainer,
              newSeedTypes: newSeedTypes,
              newTemplates: newTemplates,
              newRoles: newRoles,
              newCurrentTenant: newTenant,
          },
      });
  } else {
      console.log("Current tenant remains unchanged.");
  }
  };

  const getCurrentRoles = async (tenant) => {
    if (!tenant?.tenantId) {
      return 0;
    }
    const url = `${TENANTS_BASE_URL}/${tenant?.tenantId}/users/${state.userInformation.userId}/role`;
    const result = await HttpAgent.get(url);
    return result?.data?.roles;
  };

  const setCurrentContainer = async (container) => {
    if (!container?.id) {
      return;
    }

   const newSessionInformation = {...sessionInformation, lastSelectedTenantId: state.currentTenant?.tenantId, lastSelectedContainerId: container.id};
   setSessionInformation(newSessionInformation);
    const newSeedTypes = await loadAccessibleSeedTypesAsync(state?.currentTenant, container, true, 'seedVariantTypes');
    const payload = {
      newSeedTypes: newSeedTypes,
      newCurrentContainer: container,
    };

    dispatch({
      type: SET_CURRENT_CONTAINER,
      payload: payload,
    });
  };

  const reset = () => {
    dispatch({
      type: RESET,
      payload: initialState,
    });
  };

  const signIn = async () => {
    Auth.federatedSignIn();
  };
  const signOut = async () => {
    await retrySignOutWithErrorHandling();
  };

  const retrySignOutWithErrorHandling = async (maxRetries = 3, delay = 1000) => {
    let attempt = 0;
  
    while (attempt < maxRetries) {
      try {
        await Auth.signOut();
        console.log('Sign out successful.');
        return;
      } catch (error) {
        attempt++;
        if (error.message === 'Signout timeout fail') {
          console.log('Network issue detected, retrying...');
        } else {
          console.error('Sign out failed due to other error:', error);
        }
  
        if (attempt >= maxRetries) {
          console.error('Sign out failed after maximum retries:', error);
        }
  
        console.log(`Retrying sign out... Attempt ${attempt}`);
        await new Promise(resolve => setTimeout(resolve, delay));
        delay *= 2;
      }
    }
  }

  const signUp = async (username, password) => {
    const user = await Auth.currentAuthenticatedUser(); // get user authentication
    const idToken = user.signInUserSession.idToken.getJwtToken(); // get session token

    AWS.config.region = awsconfig.Auth.region;

    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
      IdentityPoolId: awsconfig.Auth.identityPoolId,
      Logins: {
        [`cognito-idp.eu-central-1.amazonaws.com/${awsconfig.Auth.userPoolId}`]: idToken,
      },
    });

    let identityId = AWS.config.credentials.identityId;

    let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
    const params = {
      DesiredDeliveryMediums: ['EMAIL'],
      ForceAliasCreation: false,
      UserAttributes: [
        {
          Name: 'family_name',
          Value: 'sdfs',
        },
        {
          Name: 'given_name',
          Value: 'Asfdsdfndi',
        },
        {
          Name: 'email',
          Value: 'daniel2011r@outlook.de',
        },
        {
          Name: 'email_verified',
          Value: 'true',
        },
      ],
      Username: 'daniel2011r@outlook.de',
      UserPoolId: awsconfig.Auth.userPoolId,
    };

    cognitoidentityserviceprovider.adminCreateUser(params, function (err, result) {
      if (err) {
        console.error(err);
      }
      if (result) {
      }
    });
  };

  const setAccessibleSeedTypes = async (container) => {
    try {
      // if(!state?.currentContainer) return [];
      let url;
      if (container) {
        url = `api/v1/tenants/${state.currentTenant?.tenantId}/Containers/${container?.id}/SeedTypes`;
      } else if (state?.currentContainer) {
        url = `api/v1/tenants/${state.currentTenant?.tenantId}/Containers/${state?.currentContainer?.id}/SeedTypes`;
      } else {
        return;
      }
      let result = await HttpAgent.get(url);
      dispatch({
        type: SET_ACCESSIBLE_SEEDTYPES,
        payload: result?.data?.seedTypes,
      });
      return result?.data?.seedTypes;
    } catch (error) {
    } finally {
    }
  };

  const setAccessibleTemplates = async () => { };

  const getUserAttributes = async (cognitoUser) => {
    try {
      const attributes = await new Promise((resolve, reject) => {
        cognitoUser.getUserAttributes((error, result) => {
          if (error) {
            reject(error);
          } else {
            resolve(result);
          }
        });
      });

      const attributesObj = attributes.reduce((acc, attr) => {
        acc[attr.getName()] = attr.getValue();
        return acc;
      }, {});

    } catch (error) {
      console.error("Error getting user attributes:", error);
    }
  };


  return (
    <AuthContext.Provider
      value={{
        ...state,
        setCurrentUser,
        loadAndSetAccessibleTemplatesAsync,
        loadAndSetAccessibleSeedTypesAsync,
        loadAndSetAccessibleContainersAsync,
        loadTenantUsers,
        reset,
        loadAccessibleTenantsAsync,
        reloadAccessibleTenants,
        signIn,
        signOut,
        signUp,
        setCurrentContainer,
        setCurrentTenant,
        setAccessibleContainers,
        setAccessibleSeedTypes,
        setAccessibleTemplates,
        setAccessibleContainersAndCurrentContainer,
        updateContainer,
        updateGlobalAdminRole,
        auth,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};