import React, { useContext, useEffect, useReducer } from 'react';
import { HttpAgent } from '../../utility/HttpAgent';
import AuthContext from '../auth/AuthContext';
import {
  DELETE_SEEDTYPE,
  DELETE_SEEDVARIANTTYPE,
  SET_ALL_SEEDTYPES,
  SET_LABEL_PRINTER_TEMPLATE_NAMES,
  SET_LOADING
} from '../types/contextTypes';
import UIContext from '../ui/UIContext';
import SeedTypeManagementContext from './SeedTypeManagementContext';
import SeedTypeManagementReducer from './SeedTypeManagementReducer';
import ProcessContext from '../process/ProcessContext';
import SeedVariantTypesApiService from '../../services/apiServices/SeedVariantTypesApiService';
import { HELP_DESK_LINK } from '../../constants/helpDesk';
import SeedTypesApiService from '../../services/apiServices/SeedTypesApiService';

const SeedTypeManagementState = (props) => {
  const initialState = {
    allSeedTypes: [],
    labelPrinterTemplateNames: [],
    loading: false
  };

  const [state, dispatch] = useReducer(SeedTypeManagementReducer, initialState);
  const { addNotification } = useContext(UIContext);
  const { currentContainer, currentTenant, accessibleSeedTypes } = useContext(AuthContext);
  const { notifyError, notifyInfoRed } = useContext(ProcessContext);

  useEffect(() => {   
    if (accessibleSeedTypes) {
      dispatch({
                type: SET_ALL_SEEDTYPES,
                payload: accessibleSeedTypes,
              });
    }
  }, [currentContainer, currentTenant]);

  const setLoading = (loading) => {
    dispatch({
      type: SET_LOADING,
      payload: loading
    });
  };

  const _setLabelPrinterTemplateNames = (labelPrinterTemplateNames) => {
    dispatch({
      type: SET_LABEL_PRINTER_TEMPLATE_NAMES,
      payload: labelPrinterTemplateNames,
    });
  };

  const getAllAccessibleSeedTypesAsync = async (tenant, container, includeOutdated = false, include = null) => {
    try {
      setLoading(true);
      if (!container?.id || !tenant?.tenantId) {
        return [];
      }
      const response = await SeedTypesApiService.getAllSeedTypes(tenant?.tenantId, container?.id, includeOutdated, include);
      const seedTypes = response.data.data;

      return seedTypes;
    } catch (error) {
      console.error(error);
      if (error.code === 404) {
          notifyError(<>We have encountered an issue while loading the seed types. Could you please sign out, sign back in and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
          notifyError(<>We have encountered an issue on our side while loading the seed types. Please contact the support team {HELP_DESK_LINK}.</>);
      }
    }
    finally {
      setLoading(false);
    }
  };

  const loadLabelPrinterTemplateNames = async () => {
    setLoading(true);
    try {
      const url = `api/v1/Tenants/${currentTenant.tenantId}/LabelPrinterTemplates`;
      const result = await HttpAgent.get(url);
      if (result.status !== 'success') {
        throw new Error(result.message);
      }
      _setLabelPrinterTemplateNames(result.data.labelPrinterTemplates);
      return result?.data?.labelPrinterTemplates;
    } catch (error) {
        notifyError(<>We have encountered an issue on our side while loading the label printer templates. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error("Unexpected error while loading label printer templates.", error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSeedVariantType = async (seedVariantType) => {
    setLoading(true);
    try {
      let result = await SeedVariantTypesApiService.deleteSeedVariantType(currentTenant.tenantId, currentContainer.id, seedVariantType.seedTypeId, seedVariantType.id);
      addNotification('Deleted', 'success');

            dispatch({
                type: DELETE_SEEDVARIANTTYPE,
                payload: seedVariantType,
            });
        } catch (error) {
            if (error.message === 'Not found.') {
                notifyError(<>We have encountered an issue while deleting the seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
            }
            else {
                notifyError(<>We have encountered an issue on our side while deleting the seed variant type. Please contact support team {HELP_DESK_LINK}.</>);
            }
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

  const deleteSeedType = async (seedType) => {
    setLoading(true);
    try {
      await SeedTypesApiService.deleteSeedType(currentTenant?.tenantId, currentContainer?.id, seedType.id);
      dispatch({
        type: DELETE_SEEDTYPE,
        payload: seedType.number,
      });
      addNotification(`Deleted ${seedType.name}`, 'success');
    } catch (error) {
      if (error.message === 'Not found.') {
        notifyError(<>We have encountered an issue while deleting the seed type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while deleting the seed type. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error("Unexpected error while deleting a seed type.", error);
    } finally {
      setLoading(false);
    }
  };

  const updateSeedVariantType = async (seedVariantType) => {
    setLoading(true);
    try {
      const body = { lot: seedVariantType.lot, manufacturer: seedVariantType.manufacturer, count: seedVariantType.count, packageWeight: seedVariantType.packageWeight, tgw: seedVariantType.tgw }
      await SeedVariantTypesApiService.updateSeedVariantType(currentContainer.tenantId, currentContainer.id, seedVariantType.seedTypeId, seedVariantType.seedVariantTypeId, body);
      addNotification(`Updated seed variant type.`, 'success');
    } catch (error) {
      if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while updating the seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while updating the seed variant. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error("Unexpected error while updating a seed variant type.", error);
    } finally {
      setLoading(false);
    }
  };

  const updateSeedType = async (seedType) => {
    setLoading(true);
    try {
      await SeedTypesApiService.updateSeedType(currentTenant?.tenantId, seedType.id, seedType);
      addNotification(`Updated ${seedType.name}`, 'success');
    } catch (error) {
      if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while updating the seed type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while updating the seed type. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error("Unexpected error while updating a seed type.", error);
    } finally {
      setLoading(false);
    }
  };

  const addSeedType = async (seedType, seedVariantType) => {
    setLoading(true);
    const newSeedTypeId = await saveNewSeedType(seedType);

    if (!newSeedTypeId) {
      return;
    }

    const newSeedVariantType = {
      count: seedVariantType.seedCount,
      lot: seedVariantType.lot,
      manufacturer: seedVariantType.manufacturer,
      tgw: seedVariantType.tgw,
      packageWeight: seedVariantType.packageWeight,
      seedTypeId: newSeedTypeId
    }
    const result = await saveNewSeedVariantType(newSeedVariantType);
    if (result === 201) {
      addNotification(`Added new seed type and seed variant type.`, 'success');
      return result;
    }
  }

  const saveNewSeedType = async (seedType) => {
    try {
      const result = await SeedTypesApiService.addNewSeedType(currentTenant?.tenantId, currentContainer?.id, seedType);
      const newSeedTypeId = result.data?.data?.seedTypeId;
      return newSeedTypeId;      
    } catch (error) {
      if (error.response.data.code === 404) {
        notifyError(<>We have encountered an issue while creating the new seed type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else if (error.response.data.code === 400) {
        notifyInfoRed("One or more required properties are missing.")
      }
      else{
        notifyError(<>We have encountered an issue on our side while creating the new seed type. Please contact support team {HELP_DESK_LINK}.</>);
      }
      console.error("Unexpected error while adding a seed type.", error);
    }
  };

  const saveNewSeedVariantType = async (seedVariantType) => {
    setLoading(true);
    try {
      let result = await SeedVariantTypesApiService.addNewSeedVariantType(currentContainer.tenantId, currentContainer.id, seedVariantType.seedTypeId, seedVariantType);
      if (result.data.status !== 'success') {
        throw new Error(result.message);
      }
      return result.data.code;
    } catch (error) {
      if (error.message === 'Not found') {
          notifyError(<>We have encountered an issue while creating the new seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else {
          notifyError(<>We have encountered an issue on our side while creating the new seed variant type. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error("Unexpected error while adding a new seed variant type.", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SeedTypeManagementContext.Provider
      value={{
        ...state,
        addSeedType,
        deleteSeedVariantType,
        deleteSeedType,
        getAllAccessibleSeedTypesAsync,
        updateSeedVariantType,
        updateSeedType,
        saveNewSeedType,
        saveNewSeedVariantType,
        loadLabelPrinterTemplateNames,
      }}
    >
      {props.children}
    </SeedTypeManagementContext.Provider>
  );
};

export default SeedTypeManagementState;
