// #region Libraries
import { useContext, useState, useEffect, Fragment } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader';
// #endregion

// #region Components
import Button from "../../layout/Button";
import StatusBarButton from "../../layout/StatusBarButton";
import AutoComplete from "../../container-management/AutoComplete";
import Loading from "../../ui-elements/Loading";
// #endregion

// #region Contexts
import ProcessContext from "../../../contexts/process/ProcessContext";
import UIContext from "../../../contexts/ui/UIContext";
import AuthContext from "../../../contexts/auth/AuthContext";
// #endregion

// #region Services
import HarvestingProceduresApiService from "../../../services/apiServices/HarvestingProceduresApiService";
import ContainersApiService from "../../../services/apiServices/ContainersApiService";
import FarmhandInstallationsApiService from "../../../services/apiServices/FarmhandInstallationsApiService";
// #endregion

// #region Utilities
import useWeighingCamera from "../../../customHooks/useWeighingCamera";
import { HttpAgent } from "../../../utility/HttpAgent";
import { separateOnCapitalLetters } from "../../../utility/PascalCaseSeparator";
import { MULTI_HARVEST_CUT, SINGLE_HARVEST_CUT, SINGLE_HARVEST_ROOTED, harvestingProcedureTypes } from "../../../constants/harvestingProcedures";
import { HELP_DESK_LINK } from "../../../constants/helpDesk";
// #endregion

/**
  * `Edit` is a React component used to facilitate the update of container data.
 *
 * @component
 * @example
 * return <Create />
 */
const Edit = () => {
  // #region Context properties
  const navigate = useNavigate();
  const { containerId } = useParams();
  const { accessibleContainers, currentTenant, setAccessibleContainers } = useContext(AuthContext);
  const { notifyError, notifySuccess } = useContext(ProcessContext);
  const [containerHarvestingProcedures, setContainerHarvestingProcedures] = useState();
  const { setInfo } = useContext(UIContext);
  const container = accessibleContainers.find(c => c.id === containerId);
  const weighingCameraService = useWeighingCamera(container?.tenantId, container?.id, container?.weighingCameraId);
  // #endregion

  // #region Methods
  const getRootedAdvice = () => {
    const rootedProcedure = containerHarvestingProcedures?.find(procedure => procedure.type.includes('Rooted'));
    return rootedProcedure?.storageAdvice ?? '';
  }

  const getCutAdvice = () => {
    const cutProcedure = containerHarvestingProcedures?.find(p => p.type === SINGLE_HARVEST_CUT || p.type === MULTI_HARVEST_CUT) ?? '';
    return cutProcedure?.storageAdvice ?? '';
  }
  // #endregion

  // #region States
  const [probingFarmHand, setProbingFarmHand] = useState(false);
  const [first, setFirst] = useState(true);
  const [farmHandIsLoaded, setFarmHandIsLoaded] = useState(false);

  const cutAdvice = getCutAdvice() ?? '';
  const rootedAdvice = getRootedAdvice() ?? '';
  const initialContainerState = {
    id: {
      value: container?.id ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
    },
    name: {
      value: container?.name ?? '',
      touched: false,
      validate: function (text) {
        return text?.length >= 3;
      },
      isValid: !!container?.name,
      placeholder: 'Container name ...',
    },
    description: {
      value: container?.description ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: 'Container description ...',
      initialValue: container?.description ?? null,
    },
    farmHandName: {
      value: container?.farmHandName,
      touched: false,
      validate: async () => {
        return true;
      },
      isValid: !!container?.farmHandName,
      placeholder: '',
      initialValue: container?.farmHandName ?? ''
    },
    farmHandInstallationId: {
      value: container?.farmHandInstallationId,
      touched: false,
      validate: async () => {
        return true;
      },
      isValid: true,
      placeholder: '',
      initialValue: container?.farmHandInstallationId ?? ''
    },
    weighingCameraSettings: {
      enabled: false,
      isValid: true,
    },
    location: {
      value: {
        latitude: container?.latitude ?? null,
        longitude: container?.longitude ?? null,
        street: container?.street ?? '',
        number: container?.number ?? '',
        postalCode: container?.postalCode ?? '',
        city: container?.city ?? '',
        country: container?.country ?? ''
      },
      initialValue: {
        latitude: container?.latitude ?? null,
        longitude: container?.longitude ?? null,
        street: container?.street ?? '',
        number: container?.number ?? '',
        postalCode: container?.postalCode ?? '',
        city: container?.city ?? '',
        country: container?.country ?? ''
      },
      validate: () => true
    },
    rootedAdvice: {
      value: rootedAdvice ?? '',
      touched: false,
      validate: () => {
        return true;
      },
      isValid: !!container?.farmHandName,
      placeholder: '',
      initialValue: rootedAdvice ?? '',
    },
    cutAdvice: {
      value: cutAdvice ?? '',
      touched: false,
      validate: () => {
        return true;
      },
      isValid: !!container?.farmHandName,
      placeholder: '',
      initialValue: cutAdvice ?? ''
    },
    arloCameraIds: {
      value: [],
      touched: false,
      validate: (guid) => {
        const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
        return regex.test(guid);
      },
      isValid: true,
      placeholder: ''
    },
    weighingCameraId: {
      value: container?.weighingCameraId ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
    },
    cameras: {
      value: container?.cameras ?? [],
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
  },
  labelPrinterId: {
      value: container?.labelPrinterId ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
  },
  scaleId: {
      value: container?.scaleId ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
  },
  tenantId: {
      value: container?.tenantId ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
  },
  isVirtual: {
      value: container?.isVirtual ?? false,
      touched: false,
      validate: () => true,
      isValid: true,
  },
    harvestingProcedures: containerHarvestingProcedures
  };
  const [openCameraSettings, setOpenCameraSettings] = useState(false);
  const [initialEnabledState, setInitialEnabledState] = useState(
    container?.weighingCameraSettings?.enabled == 1 ? true : false
  );
  const [proposedEnabledState, setProposedEnabledState] = useState(null);
  const [localLoading, setLocalLoading] = useState(false);

  const [google, setGoogle] = useState(null);
  const [locationString, setLocationString] = useState('');
  const [location, setLocation] = useState({
    lat: 50.9396911,
    lng: 6.8551075,
  });
  const [openLocation, setOpenLocation] = useState(false);
  const [containerState, setContainerState] = useState(initialContainerState);
  // #endregion

  // #region useEffect

  const initiallyLoadFarmHandName = async () => {
    if (!initialContainerState?.farmHandInstallationId?.value || !container) return;

    try {
      setLocalLoading(true);
      const result = await FarmhandInstallationsApiService.getFarmHandInstallationById(initialContainerState?.farmHandInstallationId?.value);
      const { id, name } = result?.data?.data;
      setContainerState((prev) => ({
        ...prev,
        ['farmHandName']: {
          ...containerState['farmHandName'],
          value: name,
          initialValue: name ?? ''
        },
        ['farmHandInstallationId']: {
          ...containerState['farmHandInstallationId'],
          value: id ?? '',
          initialValue: id ?? ''
        },
      }));
    } catch (error) {
      console.error("Unexpected error while loading farm hand installation.", error);
      notifyError(<>We have encountered an issue on our side while trying to load farmhand installations. You can still continue updating the container, you just can not update farmhand installations at this point. If the error still exists later, please contact {HELP_DESK_LINK}.</>);
    } finally {
      setLocalLoading(false);
      setFarmHandIsLoaded(true);
    }
  };

  useEffect(() => {
    if (!containerState?.harvestingProcedures || containerState?.harvestingProcedures.length < 1) {
      setContainerState((prev) => ({
        ...prev,
        ['harvestingProcedures']: containerHarvestingProcedures,
        ['rootedAdvice']: {
          ...containerState['rootedAdvice'],
          value: getRootedAdvice(),
          validate: () => true,
          initialValue: getRootedAdvice()
        },
        ['cutAdvice']: {
          ...containerState['cutAdvice'],
          value: getCutAdvice(),
          validate: () => true,
          initialValue: getCutAdvice()
        }
      }));
    }
  }, [containerHarvestingProcedures]);

  const getContainerHarvestingProcedures = async () => {
    var harvestingProcedures = await HarvestingProceduresApiService.getManyHarvestingProcedures(currentTenant?.tenantId, containerId);
    setContainerHarvestingProcedures(harvestingProcedures.data.data);
  };

  useEffect(() => {
    setInfo(`Edit Container ${initialContainerState?.name?.value}`);
    const initialLoad = async () => {
      await initiallyLoadFarmHandName();
      await getContainerHarvestingProcedures();
    };
    initialLoad();
    const loader = new Loader({
      apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
      libraries: ['places'],
    });

    loader.load().then((g) => {
      setGoogle(g);
    });

    if (container) {
      let containerStateInitTemp = Object.entries(container).reduce((acc, [key, value]) => {
        if (!(key in containerState)) {
            console.warn(`Key "${key}" fehlt im containerState.`);
            return acc;
        }
        if (typeof acc[key]?.validate !== 'function') {
            console.warn(`validate-Methode für "${key}" fehlt oder ist nicht definiert.`);
            return acc;
        }
        return {
            ...acc,
            [key]: {
                ...acc[key],
                value,
                isValid: acc[key]?.validate(value),
            },
        };
    }, initialContainerState);
      if (container?.weighingCameraId) {
        const state = HttpAgent.get(
          `api/v1/tenants/${currentTenant?.tenantId}/Containers/${container?.id}/Devices/${container?.weighingCameraId}/State`
        ).then((state) => {
          const weighingCameraSettings = {            
            enabled: state?.data == 1 ? true : false,
            isValid: true,
          };
          setInitialEnabledState(state?.data == 1 ? true : false);

          containerStateInitTemp.weighingCameraSettings = weighingCameraSettings;
          const containerStateInit = containerStateInitTemp;
          setContainerState(containerStateInit);
        });
      }
      var lat = containerState.location.value.latitude;
      var lng = containerState.location.value.longitude;
      setLocation({
        lat: lat ? Number(lat) : 50.9396911,
        lng: lng ? Number(lng) : 6.8551075,
      });
      setLocationString((containerState.location.value.latitude && containerState.location.value.longitude) ? containerState.location.value.street + ' ' + containerState.location.value.number + ' ' + containerState.location.value.postalCode + ' ' + containerState.location.value.city + ' ' + containerState.location.value.country : '');
    }
  }, []);

  useEffect(() => {
    if (first) {
      setFirst(false);
      return;
    }

    navigateToDetails();

  }, [accessibleContainers]);

  const isAllRequiredDataPresent = () => {
    return containerHarvestingProcedures && (farmHandIsLoaded || !initialContainerState?.farmHandInstallationId?.value);
  }

  // #endregion

  const analyzeHarvestingProceduresUpdate = () => {
    const harvestingProceduresToCreate = containerState?.harvestingProcedures?.filter((updatedProcedure) =>
      !containerHarvestingProcedures?.some((existingProcedure) =>
        updatedProcedure.type === existingProcedure.type
      )
    );
    const harvestingProceduresToDelete = containerHarvestingProcedures?.filter((existingProcedure) =>
      !containerState?.harvestingProcedures?.some((updatedProcedure) =>
        existingProcedure.type === updatedProcedure.type
      )
    );

    const harvestingProceduresToUpdate = containerHarvestingProcedures?.reduce((acc, originalProcedure) => {
      const procedureToUpdate = containerState?.harvestingProcedures?.find(procedureToUpdate =>
        procedureToUpdate['type'] === originalProcedure['type']
      );

      if (!procedureToUpdate) return acc;

      const updatedStorageAdvice = procedureToUpdate.type === SINGLE_HARVEST_ROOTED
        ? containerState?.rootedAdvice?.value
        : containerState?.cutAdvice?.value;


      const updatedProcedure = { ...procedureToUpdate, storageAdvice: updatedStorageAdvice };
      if (updatedProcedure) {
        const propertiesOfOriginalProcedure = Object.keys(originalProcedure);
        const isDifferent = propertiesOfOriginalProcedure.some(prop =>
          originalProcedure[prop] !== updatedProcedure[prop]
        );

        if (isDifferent) {
          acc.push(updatedProcedure);
        }
      }
      return acc;
    }, []);

    return {
      harvestingProceduresToCreate,
      harvestingProceduresToDelete,
      harvestingProceduresToUpdate
    };
  }

  /**
   * Changes the weighing camera state between enabled / disabled.
   * @param {boolean} newState - The new enabled state (true = on, false = off)
   */
  const changeWeighingCameraState = async (newState) => {
    try {
      setContainerState((prev) => ({
        ...prev,
        ['weighingCameraSettings']: {
          ...prev.weighingCameraSettings,
          enabled: newState,
        },
      }));
    } catch (error) {
      console.error('Error while changing camera state: ', error);
    }
  };

  const closeWeighingCameraSettings = () => {

    setContainerState((prev) => ({
      ...prev,
      ['weighingCameraSettings']: {
        ...prev.weighingCameraSettings,
        enabled: (proposedEnabledState !== null && proposedEnabledState !== undefined) ? proposedEnabledState : initialEnabledState,
        isValid: true,
      },
    }));
    weighingCameraService.startAnew();
    setOpenCameraSettings(false);
  };

  const compareSettings = (settings1, settings2) => {
    // First, check if the objects have the same number of keys
    if (Object.keys(settings1).length !== Object.keys(settings2).length) {
      return false;
    }

    // Then, loop through each key in obj1 and check if the value in obj2
    // matches the value in obj1 for that key
    for (const key of Object.keys(settings1)) {
      if (settings1[key] !== settings2[key]) {
        return false;
      }
    }

    // If all the keys and values match, then the objects are identical
    return true;
  };

  const copyTextToClipboard = (text) => {
    if (!navigator.clipboard) {
      fallbackCopyTextToClipboard(text);
      return;
    }
    navigator.clipboard.writeText(text).then(
      function () {
        notifySuccess('Copied to clipboard.');
      },
      function (err) {
        notifyError('Could not copy id to clipboard');
      }
    );
  };

  const fallbackCopyTextToClipboard = (text) => {
    var textArea = document.createElement('textarea');
    textArea.value = text ? text : '';

    // Avoid scrolling to bottom
    textArea.style.top = '0';
    textArea.style.left = '0';
    textArea.style.position = 'fixed';

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) { }

    document.body.removeChild(textArea);
  };

  const getFarmHandInputValue = () => {
    if (!containerState['farmHandName']?.value) return '';

    return containerState['farmHandName']?.value;
  }

  const getLocation = (place) => {
    var number = place.address_components.find(element => element.types.includes('street_number'));
    var street = place.address_components.find(element => element.types.includes('route'));
    var postalCode = place.address_components.find(element => element.types.includes('postal_code'));
    var city = place.address_components.find(element => element.types.includes('locality') || element.types.includes('postal_town'));
    var country = place.address_components.find(element => element.types.includes('country'));

    setContainerState((prev) => ({
      ...prev,
      ['location']: {
        ...prev.location,
        value: {
          ...prev.location.value,
          number: number ? number.long_name : '',
          street: street ? street.long_name : '',
          postalCode: postalCode ? postalCode.long_name : '',
          city: city ? city.long_name : '',
          country: country ? country.long_name : '',
        }
      },
    }));
    setLocationString(place.formatted_address);
  }

  const handleAbort = (event) => {
    event?.persist();
    navigateToDetails();
  }

  const handleAddHarvestProcedure = (event) => {
    event.persist();
    const selectedHarvestingProcedureToAdd = document.querySelector('#edit-container_harvestingProcedures')?.value;
    if (containerState?.harvestingProcedures?.find(i => i.type === selectedHarvestingProcedureToAdd)) {
      return;
    }
    const harvestingProcedure = harvestingProcedureTypes?.find((option) => option === selectedHarvestingProcedureToAdd);
    const newHarvestingProcedure = {
      storageAdvice: harvestingProcedure === SINGLE_HARVEST_ROOTED ? containerState?.rootedAdvice?.value : containerState?.cutAdvice?.value,
      type: harvestingProcedure
    }

    const newHarvestingProcedures = [...containerState.harvestingProcedures ?? [], newHarvestingProcedure];
    setContainerState((prev) => ({
      ...prev,
      ['harvestingProcedures']: newHarvestingProcedures,
    }));
  };

  const handleRemoveHarvestProcedure = (harvestingProcedure) => {
    const newSelectedHarvestingProcedures = containerState?.harvestingProcedures.filter((procedure) => procedure.type !== harvestingProcedure.type);
    setContainerState((prev) => ({
      ...prev,
      ['harvestingProcedures']: newSelectedHarvestingProcedures,
    }));
  }

  const Marker = () => {
    return <>
      <div className="pin"></div>
      <div className="pulse"></div>
    </>
  }

  const navigateToDetails = () => {
    const updatedContainer = accessibleContainers.find(c => c.id === container?.id);
    if (!updatedContainer) return;
    navigate(`/management/containers/${updatedContainer.id}/details`);
  }

  const onChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    const newValue = event.currentTarget.value;
    
    setContainerState((prev) => ({
      ...prev,
      [key]: {
        ...containerState[key],
        value: newValue,
        isValid: containerState[key]?.validate(newValue),
        touched: true,
      },
    }));
  };

  const onCheckFarmHand = async (event) => {
    setProbingFarmHand(true);
    event.persist();
    const farmHandName = event?.currentTarget?.value;

    if (farmHandName == containerState['farmHandName'].initialValue) {
      setProbingFarmHand(false);
      setContainerState((prev) => ({
        ...prev,
        ['farmHandInstallationId']: {
          ...containerState['farmHandInstallationId'],
          value: farmHandName,
          touched: false,
        },
      }));
      return;
    }

    if (!farmHandName) {
      setProbingFarmHand(false);
      setContainerState((prev) => ({
        ...prev,
        ['farmHandInstallationId']: {
          ...containerState['farmHandInstallationId'],
          value: '',
          isValid: true,
          touched: true,
        },
      }));
      return;
    }

    let farmHandInstallationId;
    try {
      const result = await FarmhandInstallationsApiService.getFarmHandInstallationId(farmHandName);
      farmHandInstallationId = result.data?.data?.id;

    } catch (error) {
      setProbingFarmHand(false);
      setContainerState((prev) => ({
        ...prev,
        ['farmHandName']: {
          ...containerState['farmHandName'],
          value: initialContainerState.farmHandName?.value,
          isValid: containerState['farmHandName']?.validate(farmHandName),
          touched: true,
        },
        ['farmHandInstallationId']: {
          ...containerState['farmHandInstallationId'],
          value: initialContainerState.farmHandInstallationId?.value,
          isValid: containerState['farmHandInstallationId']?.validate(farmHandName),
          touched: true,
        },
      }));
      let farmerAppError = error?.response?.data;
      if (!farmerAppError) {
        console.error("Unexpected error while checking farmhand id.", error);
        notifyError(<>We have encountered an issue on our side while checking the given farmhand id. You can still continue updating the container and add the farmhand installation later. If the error still exists, please contact {HELP_DESK_LINK}.</>);
        return;
      };

      if (farmerAppError.code === 404) {
        notifyError('The given farmhand installation does not exist. Please select another farmhand installation and try again.');
        return;
      }

      if (farmerAppError.code === 500) {
        notifyError(<>Cannot check if the given farmhand id exists. You can still continue updating the container and add the farmhand installation later. If the error persists, please contact {HELP_DESK_LINK}.</>);
        return;
      };

      notifyError(<>We have encountered an issue on our side while checking the given farmhand id. You can still continue updating the container and add the farmhand installation later. If the error still exists, please contact {HELP_DESK_LINK}.</>);
      return;
    }

    let containerId;
    try {
      const result = await FarmhandInstallationsApiService.getContainerForFarmHandInstallationId(farmHandInstallationId);
      containerId = result?.data?.data?.id;
      if (containerId) {
        setProbingFarmHand(false);
        notifyError('The given farmhand id is already in use. Please try a different farmhand id.');
        return;
      }
    } catch (error) {
      console.error("Unexpected error while checking farmhand id.", error);
      setProbingFarmHand(false);
      notifyError(<>We have encountered an issue on our side while checking the given farmhand id. You can still continue updating the container and add the farmhand installation later. If the error still exists, please contact {HELP_DESK_LINK}.</>);
      return;
    }
    if (farmHandName == '' || farmHandInstallationId) {
      setProbingFarmHand(false);
      setContainerState((prev) => ({
        ...prev,
        ['farmHandName']: {
          ...containerState['farmHandName'],
          farmHandName,
          isValid: containerState['farmHandName']?.validate(farmHandName),
          touched: true,
        },
        ['farmHandInstallationId']: {
          ...containerState['farmHandInstallationId'],
          value: farmHandInstallationId,
          isValid: true,
          touched: true,
        },
      }));
      notifySuccess('Farmhand installation is available.');
      return;
    }
    setProbingFarmHand(false);
    notifyError(<>We have encountered an issue on our side while checking the given farmhand id. Please contact {HELP_DESK_LINK}.</>);
  };

  const onEnabledChange = (event) => {
    event.persist();
    changeWeighingCameraState(!containerState?.weighingCameraSettings?.enabled);
  };

  const openWeighingCameraSettings = async () => {
    try {
      if (!container) return;
      setOpenCameraSettings(true);
      weighingCameraService.startAnew();
     
      if (!proposedEnabledState) {
        weighingCameraService._getState().then((result) => {
          setInitialEnabledState(result);
        });
      }
    } catch (err) {
      notifyError(<>We have encountered an issue on our side while loading the weighing camera state. Please contact the support team {HELP_DESK_LINK}.</>);
      console.log(err);
    }
  };

  const renderLocationContainer = () => {
    return <Fragment>
      <AutoComplete locationPicked={(place) => {
        getLocation(place);
        setLocation({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
        setContainerState((prev) => ({
          ...prev,
          ['location']: {
            ...prev.location,
            value: {
              ...prev.location.value,
              latitude: place.geometry.location.lat(),
              longitude: place.geometry.location.lng(),
            }
          },
        }));
      }}
        currentLocation={locationString} />
      <div className='location-map-container'>
        {location && <GoogleMapReact
          id='location-map'
          bootstrapURLKeys={{ key: import.meta.env.VITE_GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
          center={location}
          defaultZoom={17}
          options={{
            fullscreenControl: false,
          }}
          onClick={ev => {
            const latlng = {
              lat: parseFloat(ev.lat),
              lng: parseFloat(ev.lng),
            };

            const geocoder = new google.maps.Geocoder();

            geocoder
              .geocode({ location: latlng })
              .then((response) => {
                if (response.results[0]) {
                  getLocation(response.results[0]);
                  setLocation(latlng);
                  setContainerState((prev) => ({
                    ...prev,
                    ['location']: {
                      ...prev.location,
                      value: {
                        ...prev.location.value,
                        latitude: parseFloat(ev.lat),
                        longitude: parseFloat(ev.lng),
                      }
                    },
                  }));
                  (document.getElementById('location-search')).value = response.results[0].formatted_address;
                } else {
                  console.log("No results found");
                }
              })
              .catch((error) => {
                notifyError(<>We have encountered an issue. Could you please close the location popup and try again. If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                console.error(error);
              });
          }}
        >
          <Marker lat={location.lat} lng={location.lng}></Marker>
        </GoogleMapReact>}
      </div>
    </Fragment>
  };  

  const shouldConfirmBeDisabled = () => {
    const oldLocation = containerState.location.initialValue;
    const newLocation = containerState.location.value;
    const singleCutProcedure = containerState?.harvestingProcedures?.find(p => p.type === SINGLE_HARVEST_CUT);
    const initialSingleCutProcedure = containerHarvestingProcedures?.find(p => p.type === SINGLE_HARVEST_CUT);

    const singleCutAdvice = singleCutProcedure?.storageAdvice ?? '';

    const rootedProcedure = containerState?.harvestingProcedures?.find(p => p.type === SINGLE_HARVEST_ROOTED);
    const initialRootedProcedure = containerHarvestingProcedures?.find(p => p.type === SINGLE_HARVEST_ROOTED);
    const singleRootedAdvice = rootedProcedure?.storageAdvice ?? '';
    const multiCutProcedure = containerState?.harvestingProcedures?.find(p => p.type === MULTI_HARVEST_CUT);
    const initialMultiCutProcedure = containerHarvestingProcedures?.find(p => p.type === MULTI_HARVEST_CUT);
    const multiCutAdvice = multiCutProcedure?.storageAdvice ?? '';

    let shouldBeDisabled = true;
    if (!compareSettings(oldLocation, newLocation)) {
      shouldBeDisabled = false;
    }
    if (weighingCameraService.state !== proposedEnabledState && proposedEnabledState != null) {
      shouldBeDisabled = false;
    }

    if ((containerState?.description?.value != containerState?.description?.initialValue)) {
      shouldBeDisabled = false;
    }

    const initialHarvestingProcedureTypes = containerHarvestingProcedures?.map(p => (p.type));
    const currentHarvestingProcedureTypes = containerState?.harvestingProcedures?.map(p => (p.type));

    const stringA = initialHarvestingProcedureTypes?.join();
    const stringB = currentHarvestingProcedureTypes?.join();

    const typesAreEqual = stringA === stringB;

    if (!typesAreEqual) {
      shouldBeDisabled = false;
    }

    if ((singleCutProcedure || multiCutProcedure) && containerState?.cutAdvice?.initialValue !== containerState?.cutAdvice?.value) {
      shouldBeDisabled = false;
    }

    if (rootedProcedure && containerState?.rootedAdvice?.initialValue !== containerState?.rootedAdvice?.value) {
      shouldBeDisabled = false;
    }

    if ((singleCutAdvice !== containerState?.cutAdvice?.value) && singleCutProcedure) {
      if ((!containerState?.cutAdvice?.value && !initialSingleCutProcedure?.storageAdvice)) {
        shouldBeDisabled = shouldBeDisabled ? true : false;

      } else {
        shouldBeDisabled = false;
      }
    }

    if (singleRootedAdvice !== containerState?.rootedAdvice?.value && rootedProcedure) {

      if ((!containerState?.rootedAdvice?.value && !initialRootedProcedure?.storageAdvice)) {
        shouldBeDisabled = shouldBeDisabled ? true : false;
      } else {
        shouldBeDisabled = false;
      }
    }

    if (multiCutAdvice !== containerState?.cutAdvice?.value && multiCutProcedure) {
      if ((!containerState?.cutAdvice?.value && !initialMultiCutProcedure?.storageAdvice)) {
        shouldBeDisabled = shouldBeDisabled ? true : false;
      } else {
        shouldBeDisabled = false;
      }
    }

    if (containerState?.farmHandInstallationId?.value !== containerState?.farmHandInstallationId?.initialValue) {
      if ((!containerState?.farmHandInstallationId?.value && !containerState?.farmHandInstallationId?.initialValue)) {
      } else {
        shouldBeDisabled = false;
      }
    }

    if(containerState?.weighingCameraSettings?.enabled !== initialEnabledState ) {
      shouldBeDisabled = false;
    }

    return shouldBeDisabled;
  }

  const onSave = async () => {
    if (!container) return;
    try {
      const oldLocation = containerState.location.initialValue;
      const newLocation = containerState.location.value;
      const locationHasChanged = !compareSettings(oldLocation, newLocation);
      const containerPropertiesHaveChanged = (containerState?.description?.value !== containerState?.description?.initialValue) || (containerState?.cutAdvice.value !== containerState?.cutAdvice.initialValue || containerState?.rootedAdvice.value !== containerState?.rootedAdvice.initialValue);
      setLocalLoading(true);
      if (locationHasChanged || containerPropertiesHaveChanged) {
        const containerData = {
          description: containerState.description.value,
          street: containerState.location.value.street,
          number: containerState.location.value.number,
          postalCode: containerState.location.value.postalCode,
          city: containerState.location.value.city,
          country: containerState.location.value.country,
          latitude: containerState.location.value.latitude,
          longitude: containerState.location.value.longitude
        };
        try {
          await ContainersApiService.updateContainer(currentTenant?.tenantId, container?.id, containerData);
        } catch (error) {
          notifyError(<>We have encountered a problem while updating the container. Please contact the support team {HELP_DESK_LINK}.</>, 'management/containers');
          return;
        }
      }
      const {
        harvestingProceduresToCreate,
        harvestingProceduresToDelete,
        harvestingProceduresToUpdate
      } = analyzeHarvestingProceduresUpdate();
      let createPromises = [];

      let updatePromises = [];

      let deletePromises = [];

      let farmHandError = '';
      let farmHandPromises = [];

      let cameraError = false;

      const bothRooted = containerHarvestingProcedures?.some(p => p.type === SINGLE_HARVEST_ROOTED) === containerState?.harvestingProcedures?.some(p => p.type === SINGLE_HARVEST_ROOTED);
      const bothSingleCut = containerHarvestingProcedures?.some(p => p.type === SINGLE_HARVEST_CUT) === containerState?.harvestingProcedures?.some(p => p.type === SINGLE_HARVEST_CUT);
      const bothMultiCut = accessibleContainers?.some(p => p.type === MULTI_HARVEST_CUT) === containerState?.harvestingProcedures?.some(p => p.type === MULTI_HARVEST_CUT);

      const theyAreEqual = bothRooted && bothSingleCut && bothMultiCut;

      let proceduresError = false;
      if ((!theyAreEqual) || containerState?.cutAdvice.value !== containerState?.cutAdvice.initialValue || containerState?.rootedAdvice.value !== containerState?.rootedAdvice.initialValue) {
        if (harvestingProceduresToCreate?.length > 0) {
          try {

            for (const procedure of harvestingProceduresToCreate) {
              const newProcedure = {
                containerId: procedure?.containerId,
                storageAdvice: procedure?.type === SINGLE_HARVEST_ROOTED ? containerState?.rootedAdvice?.value : containerState?.cutAdvice?.value,
                type: procedure.type
              }

              const promise = HarvestingProceduresApiService.createHarvestingProcedure(currentTenant?.tenantId, container?.id, newProcedure);
              createPromises.push(promise);

            }

          } catch (error) {
            console.error("Error while creating harvesting procedure: ", error);
            proceduresError = true;
          }
        }

        if (harvestingProceduresToUpdate?.length > 0) {
          try {

            for (const procedure of harvestingProceduresToUpdate) {

              const promise = HarvestingProceduresApiService.updateHarvestingProcedure(currentTenant?.tenantId, container?.id, procedure);
              updatePromises.push(promise);

            }

          } catch (error) {
            console.error("Error while updating harvesting procedure: ", error);
            proceduresError = true;
          }
        }

        if (harvestingProceduresToDelete?.length > 0) {
          try {

            for (const procedure of harvestingProceduresToDelete) {

              const promise = HarvestingProceduresApiService.deleteHarvestingProcedure(currentTenant?.tenantId, container?.id, procedure.id);
              deletePromises.push(promise);
            }

          } catch (error) {
            console.error("Error while deleting harvesting procedure: ", error);
            proceduresError = true;
          }
        }
      }
      try {
        if (containerState?.farmHandName?.value != containerState?.farmHandName?.initialValue && containerState?.farmHandName?.value !== (containerState?.farmHandName?.initialValue ?? '')) {

          let farmHandInstallationId;
          if (!containerState?.farmHandName.value && containerState?.farmHandName?.initialValue) {

            const result = await FarmhandInstallationsApiService.getFarmHandInstallationId(containerState?.farmHandName?.initialValue);
            farmHandInstallationId = result.data?.data?.id;

            if (farmHandInstallationId) {
              const farmHandPromise = FarmhandInstallationsApiService.unassignFarmHandInstallationFromContainer(currentTenant?.tenantId, container?.id, farmHandInstallationId);
              farmHandPromises.push(farmHandPromise);
            }
          } else if (containerState?.farmHandName?.value) {
            const result = await FarmhandInstallationsApiService.getFarmHandInstallationId(containerState?.farmHandName?.value);
            farmHandInstallationId = result?.data?.data?.id;
            const farmHandPromise = FarmhandInstallationsApiService.assignFarmHandInstallationToContainer(currentTenant?.tenantId, container?.id, {
              farmHandInstallationId
            }).catch(error => {
              console.error("Farmhand installation already in use.");
              farmHandError = true;
            });
            farmHandPromises.push(farmHandPromise);
          }
        }

        if (weighingCameraService.state !== proposedEnabledState) {
          try {
            const stateBody = { data: proposedEnabledState ? 1 : 0 };
            await HttpAgent.put(
              `api/v1/tenants/${currentTenant?.tenantId}/Containers/${container?.id}/Devices/${container?.weighingCameraId}/State`,
              JSON.stringify(stateBody)
            );
          } catch (error) {
            if (error === 'Not found') {
              notifyError(<>We have encountered an issue while updating the camera state. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
            }
            else {
              notifyError(<>We have encountered an issue on our side while saving the new weighing camera settings. Please contact support team {HELP_DESK_LINK}.</>);
            }
            console.error(error);
            cameraError = true;
          }
        }
      } catch (error) {
        farmHandError = true;
      }

      let allIsSuccessful = true;

      try {
        await Promise.all([...createPromises, ...updatePromises, ...deletePromises, ...farmHandPromises]);

        if (proceduresError || farmHandError || !allIsSuccessful) {
          notifyError(<>The container has been updated, but some settings could not be applied. Please check the container and try to update again. If the error persists, contact our team at {HELP_DESK_LINK}.</>, 'management/containers');
        } else if (cameraError) {
          notifyError(<>The container has been updated, but some weghing camera settings could not be applied. Please check the container and camera settings and try to update again. If the error persists, contact our team at {HELP_DESK_LINK}.</>, 'management/containers');

        } else {
          notifySuccess('Successfully updated container.');
        }
        setAccessibleContainers(currentTenant);
      } catch (error) {
        console.error("Unexpected error while updateing container. ", error);
        allIsSuccessful = false;
      }

      if (weighingCameraService.state !== proposedEnabledState) {
        try {
          await weighingCameraService.switchState(proposedEnabledState ? 1 : 0)
        } catch (error) {
          if (error === 'Not found') {
            notifyError(<>We have encountered an issue while updating the camera state. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
          }
          else{
            notifyError(<>We have encountered an issue on our side while saving the new weighing camera settings. Please contact support team {HELP_DESK_LINK}.</>);
          }
          console.error(error);
          cameraError = true;
        }
      }
    } catch (error) {
      console.error("Unexpected error while updating container: ", error);
      notifyError(<>We have encountered an unexpected error. Please check the container and try to update again. If the error persists, contact our team at {HELP_DESK_LINK}.</>, 'management/containers');
    } finally {
      setLocalLoading(false);
      await setAccessibleContainers(currentTenant);
    }
  };

  return (localLoading || !isAllRequiredDataPresent()) ? <Loading fullScreen={true} /> : <>
    <div className="edit-container-container-wrapper">
      <div className="edit-container-container">
        <div className='edit-container-title'>General Information</div>
        <div className="container-name-icon"><i className="fa-solid fa-signature"></i></div>
        <div className="container-name-label">Name</div>
        <input className='container-name-input disabled-italic' type='text' disabled value={containerState?.name?.value} />
        <div className="container-location-icon"><i className="fa-solid fa-location-dot"></i></div>
        <div className="container-location-label">Location</div>
        <div className="container-location-details">{`${containerState?.location?.value?.street} ${containerState?.location?.value?.number}, ${containerState?.location?.value?.postalCode} ${containerState?.location?.value?.city}, ${containerState?.location?.value?.country}`}</div>
        <div className="container-location-popup-button" onClick={() => { setOpenLocation(true); }}><i className="fa-solid fa-pen"></i></div>
        <div className="container-devices-title">Devices</div>
        <div className="container-scale-icon"><i className="fa-solid fa-scale-balanced"></i></div>
        <div className="container-scale-label">Scale</div>
        <div className="container-scale-id-display">{container?.scaleId}<i className='fa-solid fa-copy container-scale-id-copy-icon' onClick={() => copyTextToClipboard(container?.scaleId)}></i></div>
        <div className="container-labelprinter-icon"><i className="fa-solid fa-print"></i></div>
        <div className="container-labelprinter-label">Label Printer</div>
        <div className="container-labelprinter-id-display">{container?.labelPrinterId}<i className="fa-solid fa-copy container-labelprinter-id-copy-icon" onClick={() => copyTextToClipboard(container?.labelPrinterId)}></i></div>
        <div className="container-bev-camera-icon"><i className="fa-solid fa-camera"></i></div>
        <div className="container-bev-camera-label">Bird's Eye View Camera</div>
        <div className="container-bev-camera-enabled-icon">{containerState?.weighingCameraSettings?.enabled ? <i className="fa-solid fa-circle"></i> : <i className="fa-regular fa-circle"></i>}</div>
        <div className="container-bev-camera-id-display">{container?.weighingCameraId}<i className="fa-solid fa-copy container-bev-camera-id-copy-icon" onClick={() => copyTextToClipboard(container?.weighingCameraId)}></i></div>
        <div className="container-bev-camera-settings" onClick={openWeighingCameraSettings}><i className="fa-solid fa-gear"></i></div>
        <div className="container-misc-title">Miscellaneous</div>
        <div className="container-farmhand-icon"><i className="fa-solid fa-tractor"></i></div>
        <div className="container-farmhand-label">Farmhand ID</div>
        <input className={probingFarmHand ? 'container-farmhand-id-input disabled-italic' : "container-farmhand-id-input"} type='text' name='farmHandName' value={probingFarmHand ? `Checking FarmHand Id "${containerState['farmHandName']?.value}"` : getFarmHandInputValue()} onChange={onChange} onBlur={onCheckFarmHand} />
        <div className={containerState?.harvestingProcedures?.some(hp => hp.type.includes("Root")) ? "container-root-advice-icon" : "container-root-advice-icon disable"}><i className="fa-solid fa-box-archive"></i></div>
        <div className={containerState?.harvestingProcedures?.some(hp => hp.type.includes("Root")) ? "container-root-advice-label" : "container-root-advice-label disable"}>Storage Advice Text for Root Produces</div>
        <textarea className="container-root-advice-input" disabled={!containerState?.harvestingProcedures?.some(hp => hp.type.includes("Root"))} name='rootedAdvice' placeholder={containerState?.rootedAdvice?.placeholder} value={containerState?.rootedAdvice?.value} onChange={onChange} />
        <div className={containerState?.harvestingProcedures?.some(hp => hp.type.includes("Cut")) ? "container-cut-advice-icon" : "container-cut-advice-icon disable"}><i className="fa-solid fa-box-archive"></i></div>
        <div className={containerState?.harvestingProcedures?.some(hp => hp.type.includes("Cut")) ? "container-cut-advice-label" : "container-cut-advice-label disable"}>Storage Advice Text for Cut Produces</div>
        <textarea className="container-cut-advice-input" disabled={!containerState?.harvestingProcedures?.some(hp => hp.type.includes("Cut"))} name='cutAdvice' placeholder={containerState?.cutAdvice?.placeholder} value={containerState?.cutAdvice?.value ?? ''} onChange={onChange} />
        <div className="container-description-icon"><i className="fa-regular fa-comment-dots"></i></div>
        <div className="container-description-label">Description</div>
        <textarea className="container-description-input" value={containerState?.description?.value ?? ''} onChange={onChange} name='description' />
        <div className="container-camera-icon"><i className="fa-solid fa-video"></i></div>
        <div className="container-camera-label">Arlo Camera Ids</div>
        <input className={"container-camera-input"} type='text' value='' disabled />
        <div className="container-camera-list"></div>
        <div className="container-procedures-icon"><i className="fa-solid fa-wheat-awn"></i></div>
        <div className="container-procedures-label">Harvesting Procedures</div>
        <select
          id='edit-container_harvestingProcedures'
          className='container-procedures-dropdown'
        >
          {harvestingProcedureTypes?.map((harvestingProcedure, index) => (
            <option key={harvestingProcedure + index} value={harvestingProcedure} disabled={containerState.harvestingProcedures?.some(type => type?.type === harvestingProcedure)} >
              {separateOnCapitalLetters(harvestingProcedure)}
            </option>
          ))}
        </select>
        <button
          className='container-procedures-add-button'
          onClick={handleAddHarvestProcedure}
        >
          <i className='fa-solid fa-plus' ></i>
        </button>
        <div className='container-procedures-list'>
          {containerState?.harvestingProcedures?.map((option, index) => (
            <div key={option?.value ?? '' + index} className='container-procedures-item' >
              <span className="container-procedure-name">
                {separateOnCapitalLetters(option?.type ?? '')}
              </span>
              <i className="fa-solid fa-circle-minus container-procedures-remove-icon" onClick={() => handleRemoveHarvestProcedure(option)} ></i>
            </div>
          ))}
        </div>
      </div>
      <StatusBarButton
        statusSlot={3}
        label='Abort'
        clickHandler={handleAbort} icon='fas fa-ban' type='inline' />
      <StatusBarButton
        className='seed-card-btn'
        label='Confirm'
        statusSlot={5}
        icon='fas fa-check'
        type='inline'
        reversed={true}
        clickHandler={onSave}
        disabled={probingFarmHand || shouldConfirmBeDisabled()}
      />
    </div>
    <Modal isOpen={openCameraSettings} className='modal-config'>
      <ModalHeader className='weighing-camera-settings-card-header'>
        <div className='h4-style'>Weighing Camera Settings</div>
      </ModalHeader>
      <ModalBody className='weighing-camera-settings-card-body'>
        <div className='row py-1 weighing-camera-settings-card-body-enabled'>
          <div className='col-md-7 weighing-camera-settings-card-body-enabled-text'>
            <label htmlFor='camera-settings-enable'>Weighing Camera enabled</label>
          </div>
          <div className='col-md-5 weighing-camera-settings-card-body-enabled-switch'>
            <label className='switch'>
              <input
                type='checkbox'
                checked={containerState.weighingCameraSettings.enabled}
                value={containerState.weighingCameraSettings.enabled}
                onChange={onEnabledChange}
              />
              <span className='slider round'></span>
            </label>
          </div>
        </div>
      </ModalBody>
      <div className='weighing-camera-settings-card-buttons'>
        <Fragment>
          <Button
            className='weighing-camera-settings-card-btn'
            label={'Cancel'}
            clickHandler={closeWeighingCameraSettings}
          />
          <Button
            className='weighing-camera-settings-card-btn'
            label={'Ok'}
            clickHandler={async () => {
              setProposedEnabledState(containerState?.weighingCameraSettings?.enabled);
              setOpenCameraSettings(false);
            }}
            disabled={false}
          />
        </Fragment>
      </div>
    </Modal>
    <Modal size="xl" centered isOpen={openLocation} className='modal-config'>
      <ModalBody className='location-card-body'>
        <div>{renderLocationContainer()}</div>
      </ModalBody>
      <div>
        <Fragment>
          <Button
            className='location-card-btn'
            label={'Done'}
            clickHandler={async () => {
              setOpenLocation(false);
            }}
            disabled={false}
          />
        </Fragment>
      </div>
    </Modal>
  </>
}

export default Edit;