import React, { useReducer } from 'react';
import { v4 } from 'uuid';
import {
  CLEAR_INFO,
  CLEAR_NOTIFICATION,
  CLEAR_ERROR,
  SET_INFO,
  SET_LOADING,
  SET_NOTIFICATION,
  SET_LOADING_ELEMENT_NAME,
  CLEAR_INFORMATION,
  CLEAR_PERSISTANT_NOTIFICATION,
  SET_SIGNALR_IS_CONNECTED,
} from '../types/contextTypes';
import UIContext from './UIContext';
import UIReducer from './UIReducer';

const UIState = (props) => {
  const initialState = {
    info: 'START A PROCESS',
    notifications: [],
    error: null,
    errorBoundary: null,
    TTL: 9000,
    processStarted: false,
    loadingElementName: '',
    loading: false,
    signalRIsConnected: false,
    processIcons: [
      'fas fa-spa',
      'fas fa-share-square',
      'fas fa-arrows-alt',
      'fas fa-tractor',
      'fas fa-balance-scale',
      'fas fa-dumpster',
      'fas fa-trash',
      'fas fa-clipboard',
      'fas fa-camera'
    ],
  };

  const [state, dispatch] = useReducer(UIReducer, initialState);  
  
  const setLoading = (isLoading) => {
    dispatch({
      type: SET_LOADING,
      payload: isLoading,
    });
  };

  const setSignalRConnectionStatus = (isConnected) => {
    dispatch({
      type: SET_SIGNALR_IS_CONNECTED,
      payload: isConnected,
    });
  };

  const setLoadingElementName = (name) => {
    dispatch({
      type: SET_LOADING_ELEMENT_NAME,
      payload: name
    })
  }

  const setInfo = (text) => {
    dispatch({
      type: SET_INFO,
      payload: text,
    });
  };

  const clearInfo = () => {
    dispatch({
      type: CLEAR_INFO,
    });
  };

  const addNotification = (text, type = 'info', errorBoundary = null) => {
    const id = v4();    

    if (type === 'error-stay') {
      dispatch({
        type: SET_NOTIFICATION,
        payload: { id, text, type, errorBoundary },
      });
      return;
    }

    dispatch({
      type: SET_NOTIFICATION,
      payload: { id, text, type },
    });

    let actualTTL = state.TTL;
    if (type === 'info') {
      actualTTL = 5000;
    }
    else if (type === 'success') {
      actualTTL = 3000;
    }
    else if (type === 'error') {
      actualTTL = 9000;
    };

    setTimeout(() => removeNotification(id), actualTTL);
  };

  const addRedInfoNotification = (text, type = 'info') => {
    const id = v4();
    type = 'info-red'
    dispatch({
      type: SET_NOTIFICATION,
      payload: { id, text, type },
    });

    setTimeout(() => removeNotification(id), 3000);
  }

  const removeError = () => {
    dispatch({
      type: CLEAR_ERROR
    });
  } 

  const removeInformation = () => {
    dispatch({
      type: CLEAR_INFORMATION
    });
  }

  const removeNotification = (id) => {    
    dispatch({
      type: CLEAR_NOTIFICATION,
      payload: id,
    });
  };  

  const getSignalRConnectionStatus = () => {    
    return state.signalRIsConnected;
  }

  return (
    <UIContext.Provider
      value={{
        ...state,
        addNotification,
        addRedInfoNotification,
        removeNotification,
        removeError,
        removeInformation,
        setInfo,
        clearInfo,
        setLoading,
        setLoadingElementName,
        setSignalRConnectionStatus,
        getSignalRConnectionStatus
      }}
    >
      {props.children}
    </UIContext.Provider>
  );
};

export default UIState;
