import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Contexts for managing UI, authentication, and seed type state
import UIContext from "../../../contexts/ui/UIContext";
import AuthContext from '../../../contexts/auth/AuthContext';
import SeedTypeManagementContext from '../../../contexts/seed-type-management/SeedTypeManagementContext';

// Component and custom hook imports
import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';
import LabeledInputField from '../../layout/LabeledInputField';

/**
 * Component for creating a new seed type entry. It allows users to input data
 * related to a seed type and its variants, validating input and managing state via context and local state.
 */
const Create = () => {

    // Utilizing useContext hook to access global state from React context
    const { currentContainer, currentTenant, accessibleSeedTypes, loadAndSetAccessibleSeedTypesAsync } =
        useContext(AuthContext);
    const { addSeedType } = useContext(SeedTypeManagementContext);
    const { setInfo } = useContext(UIContext);

    const navigate = useNavigate();

    // Defines the initial state for seed type form fields with validation rules
    const initialSeedTypeState = {
        name: {
            value: '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Name must have three or more characters.'
        },
        scientificName: {
            value: '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Scientific name must have three or more characters.'
        },
        commonName: {
            value: '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Common name must have three or more characters.'
        },
        number: {
            value: 0,
            touched: false,
            validate: function (text) {
                if (text === '' || /^0+$/.test(text)) {
                    return;
                }
                const sanitizedText = text.toString().replace(/^0+/, '');
                const isAvailable =
                    accessibleSeedTypes?.filter((seedType) => !seedType.outdated).map((seedType) => seedType.number.toString()).includes(sanitizedText.toString()) == false;
                return isAvailable;
            },
            isValid: false,
            placeholder: 0,
            errorMessage: 'Number is already in use or has no valid value.'
        },
        containerId: {
            value: currentContainer?.id ?? '',
            touched: false,
            validate: function () {
                return true;
            },
            isValid: true,
            errorMessage: ''
        },
        seedsPerPlug: {
            value: 1,
            touched: true,
            validate: function (str) {
                const num = parseInt(str, 10);
                return num >= 1;
            },
            isValid: true,
            errorMessage: 'Seeds per plug cannot be 0.'
        }
    };

    // Defines the initial state for seed variant type form fields with validation rules.
    const initialSeedVariantTypeState = {
        tgw: {
            value: 0,
            touched: false,
            validate: function (number) {
                return number > 0;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Tgw must be set.'
        },
        packageWeight: {
            value: 0,
            touched: false,
            validate: function (number) {
                return number > 0;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Package weight must be set.'
        },
        seedCount: {
            value: 0,
            touched: false,
            validate: function (number) {
                return number > 0;
            },
            isValid: false,
            placeholder: '',
            errorMessage: 'Seed count must be set.'
        },
        lot: {
            value: String.empty,
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: false,
            placeholder: 'Please enter the lot number',
            errorMessage: 'Lot number must have three or more characters.'
        },
        manufacturer: {
            value: String.empty,
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: false,
            placeholder: 'Please enter the manufacturer',
            errorMessage: 'Manufacturer must have three or more characters.'
        },
    }

    // Local loading state to manage UI loading indicators
    const [localLoading, setLocalLoading] = useState(false);

    // Set page information on component mount
    useEffect(() => {
        setInfo('Add new Seed Type');
    }, []);

    // State management for form fields, including validation and user input
    const [seedTypeState, setSeedTypeState] = useState(initialSeedTypeState);
    const [seedVariantTypeState, setSeedVariantTypeState] = useState(initialSeedVariantTypeState);

    // Validates the entire form state to enable or disable the save button
    const isStateValid = Object.values(seedTypeState)
        .map((entry) => entry.isValid)
        .every((isValid) => isValid);

    // Handlers for form input changes, updating local state and performing validation
    const onSeedTypeChange = (event) => {
        event.persist();
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setSeedTypeState((prev) => ({
            ...prev,
            [key]: {
                ...seedTypeState[key],
                value,
                isValid: seedTypeState[key].validate(value),
                touched: true,
            },
        }));
    };

    const onSeedVariantTypeChange = (event) => {
        event.persist();
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setSeedVariantTypeState((prev) => ({
            ...prev,
            [key]: {
                ...seedVariantTypeState[key],
                value,
                isValid: seedVariantTypeState[key].validate(value),
                touched: true,
            },
        }));
    };

    const onSeedCountChange = (event) => {
        event.persist();
        const newSeedCount = event.currentTarget.value;      

        setSeedVariantTypeState((prev) => ({
            ...prev,
            ['seedCount']: {
                ...seedVariantTypeState['seedCount'],
                value: newSeedCount,
                isValid: seedVariantTypeState['seedCount'].validate(newSeedCount),
                touched: true,
            }
        }));
    }

    const onSeedCountBlur = () => {

        if (seedVariantTypeState?.tgw?.value > 0 && !seedVariantTypeState?.packageWeight?.value) {
            const newPackageWeight = seedVariantTypeState?.tgw?.value * (seedVariantTypeState?.seedCount?.value) / 1000
            setSeedVariantTypeState((prev) => ({
                ...prev,
                ['packageWeight']: {
                    ...seedVariantTypeState['packageWeight'],
                    value: newPackageWeight,
                    isValid: seedVariantTypeState['packageWeight'].validate(newPackageWeight),
                    touched: true,
                },
            }));
            return;
        }

        if (seedVariantTypeState?.packageWeight?.value && !seedVariantTypeState?.tgw?.value) {
            const newTgw = (1000 * seedVariantTypeState?.packageWeight?.value) / seedVariantTypeState?.seedCount?.value
            setSeedVariantTypeState((prev) => ({
                ...prev,
                ['tgw']: {
                    ...seedVariantTypeState['tgw'],
                    value: newTgw,
                    isValid: seedVariantTypeState['tgw'].validate(newTgw),
                    touched: true,
                },
            }));
            return;
        }
    }

    const onPackageWeightChange = (event) => {
        event.persist();
        const newPackageWeight = event.currentTarget.value;        

        setSeedVariantTypeState((prev) => ({
            ...prev,
            ['packageWeight']: {
                ...seedVariantTypeState['packageWeight'],
                value: newPackageWeight,
                isValid: seedVariantTypeState['packageWeight'].validate(newPackageWeight),
                touched: true,
            }
        }));
    }

    const onPackageWeightBlur = () => {

        if (seedVariantTypeState?.seedCount?.value && !seedVariantTypeState?.tgw?.value) {
            const newTgw = (1000 * seedVariantTypeState?.packageWeight?.value) / seedVariantTypeState?.seedCount?.value
            setSeedVariantTypeState((prev) => ({
                ...prev,               
                ['tgw']: {
                    ...seedVariantTypeState['tgw'],
                    value: newTgw,
                    isValid: seedVariantTypeState['tgw'].validate(newTgw),
                    touched: true,
                },
            }));
            return;
        }

        if (seedVariantTypeState?.tgw?.value && !seedVariantTypeState?.seedCount?.value) {
            const newSeedCount = Math.round(seedVariantTypeState?.packageWeight?.value / seedVariantTypeState?.tgw?.value * 1000);
            setSeedVariantTypeState((prev) => ({
                ...prev,               
                ['seedCount']: {
                    ...seedVariantTypeState['seedCount'],
                    value: newSeedCount,
                    isValid: seedVariantTypeState['seedCount'].validate(newSeedCount),
                    touched: true,
                },
            }));
            return;
        }
    };

    const onTGWChange = (event) => {
        event.persist();
        const newTGW = event.currentTarget.value;        

        setSeedVariantTypeState((prev) => ({
            ...prev,
            ['tgw']: {
                ...seedVariantTypeState['tgw'],
                value: newTGW,
                isValid: seedVariantTypeState['tgw'].validate(newTGW),
                touched: true,
            }
        }));
    };

    const onTGWBlur = () => {

        if (seedVariantTypeState?.seedCount?.value && !seedVariantTypeState?.packageWeight?.value) {
            const newPackageWeight = seedVariantTypeState?.tgw?.value * (seedVariantTypeState?.seedCount?.value) / 1000;
            setSeedVariantTypeState((prev) => ({
                ...prev,                
                ['packageWeight']: {
                    ...seedVariantTypeState['packageWeight'],
                    value: newPackageWeight,
                    isValid: seedVariantTypeState['packageWeight'].validate(newPackageWeight),
                    touched: true,
                },
            }));
            return;
        };

        if (seedVariantTypeState?.packageWeight?.value && !seedVariantTypeState?.seedCount?.value) {
            const newSeedCount = Math.round(seedVariantTypeState?.packageWeight?.value / seedVariantTypeState?.tgw?.value * 1000);
            setSeedVariantTypeState((prev) => ({
                ...prev,                
                ['seedCount']: {
                    ...seedVariantTypeState['seedCount'],
                    value: newSeedCount,
                    isValid: seedVariantTypeState['seedCount'].validate(newSeedCount),
                    touched: true,
                },
            }));
            return;
        }
    }

    // Functions to create seed type and variant objects from form state, ready for API submission
    const createSeedType = () => {
        const seedTypeProperties = Object.entries(seedTypeState).reduce((acc, [key, valueObject]) => {
            return { ...acc, [key]: valueObject.value };
        }, {});

        return seedTypeProperties;
    };

    const createSeedVariantType = () => {
        let seedVariantTypeProperties = Object.entries(seedVariantTypeState).reduce((acc, [key, valueObject]) => {
            return { ...acc, [key]: valueObject.value };
        }, {});   
        
        seedVariantTypeProperties.tgw = seedVariantTypeState.tgw?.value / 1000;
        seedVariantTypeProperties.packageWeight = seedVariantTypeState.packageWeight?.value / 1000;

        return seedVariantTypeProperties;
    }

    // Handler for save action, performing validation, submission, and navigation
    const handleSave = async () => {
        setLocalLoading(true);
        const newSeedType = createSeedType();
        const newSeedVariantType = createSeedVariantType();
        const result = await addSeedType(newSeedType, newSeedVariantType);
        if (result !== 201) {
            setLocalLoading(false);
            return;
        }
        await loadAndSetAccessibleSeedTypesAsync(currentTenant, currentContainer, false, 'seedVariantTypes');
        setLocalLoading(false);
        handleDone();
    };

    // Reset form to initial state
    const reset = () => {
        setSeedTypeState(initialSeedTypeState);
        setSeedVariantTypeState(initialSeedVariantTypeState);
    };

    // Handler for the done action, navigating back to the seed types list
    const handleDone = () => {
        navigate('/management/seed-types/list');
    };

    // Conditional rendering based on loading state
    return (!seedTypeState || !seedVariantTypeState || localLoading) ? <Loading fullScreen /> : (
        <div className='seed-management-container process-container'>
            <div className='add-new-seed-card'>
                <div className='add-seed-type-title add-seed-type'>
                    Seed Type
                </div>
                <div className='name-pos'>
                    <LabeledInputField
                        labelText='Name'
                        inputName='name'
                        value={seedTypeState?.name?.value}
                        isValid={seedTypeState?.name?.isValid}
                        touched={seedTypeState?.name?.touched}
                        inputType='text'
                        onChange={onSeedTypeChange}
                        placeholder={seedTypeState?.name?.placeholder}
                        errorMessage='Name must have at least 3 characters.'
                    />
                </div>
                <div className="scientific-name-pos">
                    <LabeledInputField
                        labelText='Scientific Name'
                        inputName='scientificName'
                        value={seedTypeState?.scientificName?.value}
                        isValid={seedTypeState?.scientificName?.isValid}
                        touched={seedTypeState?.scientificName?.touched}
                        inputType='text'
                        onChange={onSeedTypeChange}
                        placeholder={seedTypeState?.scientificName?.placeholder}
                        errorMessage='Scientific name must have at least 3 characters.'
                    />
                </div>
                <div className="common-name-pos">
                    <LabeledInputField
                        labelText='Common Name'
                        inputName='commonName'
                        value={seedTypeState?.commonName?.value}
                        isValid={seedTypeState?.commonName?.isValid}
                        touched={seedTypeState?.commonName?.touched}
                        inputType='text'
                        onChange={onSeedTypeChange}
                        placeholder={seedTypeState?.commonName?.placeholder}
                        errorMessage='Common name must have at least 3 characters.'
                    />
                </div>
                <div className="number-pos">
                    <LabeledInputField
                        labelText='Number'
                        inputName='number'
                        value={seedTypeState?.number?.value}
                        isValid={seedTypeState?.number?.isValid}
                        touched={seedTypeState?.number?.touched}
                        inputType='number'
                        step={1}
                        onChange={onSeedTypeChange}
                        placeholder={seedTypeState?.number?.placeholder}
                        errorMessage={seedTypeState?.number?.errorMessage}
                    /></div>
                <div className="seeds-per-plug-pos">
                    <LabeledInputField
                        labelText='Seeds per Plug'
                        inputName='seedsPerPlug'
                        value={seedTypeState?.seedsPerPlug?.value}
                        isValid={seedTypeState?.seedsPerPlug?.isValid}
                        touched={seedTypeState?.seedsPerPlug?.touched}
                        inputType='number'
                        step={1}
                        onChange={onSeedTypeChange}
                        placeholder={seedTypeState?.seedsPerPlug?.placeholder}
                        errorMessage={seedTypeState?.seedsPerPlug?.errorMessage}
                    /></div>
                <div className='add-seed-type-title add-seed-type-variant'>
                    Seed Type Variant Details
                </div>
                <div className="lot-pos">
                    <LabeledInputField
                        labelText='LOT'
                        inputName='lot'
                        value={seedVariantTypeState?.lot?.value}
                        isValid={seedVariantTypeState?.lot?.isValid}
                        touched={seedVariantTypeState?.lot?.touched}
                        inputType='text'
                        onChange={onSeedVariantTypeChange}
                        placeholder={seedVariantTypeState?.lot?.placeholder}
                        errorMessage={seedVariantTypeState?.lot?.errorMessage}
                    />
                </div>
                <div className="manufacturer-pos">
                    <LabeledInputField
                        labelText='Manufacturer'
                        inputName='manufacturer'
                        value={seedVariantTypeState?.manufacturer?.value}
                        isValid={seedVariantTypeState?.manufacturer?.isValid}
                        touched={seedVariantTypeState?.manufacturer?.touched}
                        inputType='text'
                        onChange={onSeedVariantTypeChange}
                        placeholder={seedVariantTypeState?.manufacturer?.placeholder}
                        errorMessage={seedVariantTypeState?.manufacturer?.errorMessage}
                    />
                </div>
                <div className="seed-count-pos">
                    <LabeledInputField
                        labelText='Seed Count'
                        inputName='seedCount'
                        value={seedVariantTypeState?.seedCount?.value}
                        isValid={seedVariantTypeState?.seedCount?.isValid}
                        touched={seedVariantTypeState?.seedCount?.touched}
                        inputType='number'
                        step={1}
                        onChange={onSeedCountChange}
                        onBlur={onSeedCountBlur}
                        placeholder={seedVariantTypeState?.seedCount?.placeholder}
                        errorMessage={seedVariantTypeState?.seedCount?.errorMessage}
                    /></div>
                <div className="package-weight-pos">
                    <LabeledInputField
                        labelText='Package Weight [g]'
                        inputName='packageWeight'
                        value={seedVariantTypeState?.packageWeight?.value}
                        isValid={seedVariantTypeState?.packageWeight?.isValid}
                        touched={seedVariantTypeState?.packageWeight?.touched}
                        inputType='number'
                        step={0.01}
                        onChange={onPackageWeightChange}
                        onBlur={onPackageWeightBlur}
                        placeholder={seedVariantTypeState?.packageWeight?.placeholder}
                        errorMessage={seedVariantTypeState?.packageWeight?.errorMessage}
                    />
                </div>

                <div className="tgw-pos">
                    <LabeledInputField
                        labelText='TGW [g]'
                        inputName='tgw'
                        value={seedVariantTypeState?.tgw?.value}
                        isValid={seedVariantTypeState?.tgw?.isValid}
                        touched={seedVariantTypeState?.tgw?.touched}
                        inputType='number'
                        step={0.01}
                        onChange={onTGWChange}
                        onBlur={onTGWBlur}
                        placeholder={seedVariantTypeState?.tgw?.placeholder}
                        errorMessage={seedVariantTypeState?.tgw?.errorMessage}
                    />
                </div>
                <div className='card-button seed-card-footer'>
                    <Fragment>
                        <StatusBarButton
                            className='seed-card-btn'
                            statusSlot={1}
                            label='Reset'
                            type='inline'
                            icon='fas fa-undo'
                            clickHandler={reset}
                        />
                        <StatusBarButton statusSlot={3} label='Abort' clickHandler={handleDone} icon='fas fa-ban' type='inline' />
                        <StatusBarButton
                            className='seed-card-btn'
                            label='Confirm'
                            statusSlot={5}
                            icon='fas fa-check'
                            type='inline'
                            reversed={true}
                            clickHandler={handleSave}
                            disabled={!isStateValid || !seedVariantTypeState.manufacturer.isValid || !seedVariantTypeState.lot.isValid}
                            />
                    </Fragment>
                </div>
            </div>
        </div>
    );
};

export default Create;