import React, { Fragment, useContext, useEffect, useState } from 'react';
import AuthContext from '../../../contexts/auth/AuthContext';
import SelectableContext from '../../../contexts/local/selectable/SelectableContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';
import SeedSlotSelectable from '../discarding/tray/SeedSlotSelectable';
import Tray from '../Tray';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../layout/BackButton';
import useConfirm from '../../confirmation/useConfirm';
import SelectionSidebar from '../../layout/Sidebar/SelectionSidebar';

const EmptyTraySlotSelector = ({ forwardTo, backwardTo }) => {
  const navigate = useNavigate();

  const { loadOccupancy, occupancyArray, activeProcessId, continueProcess, additionalProcessInformation } =
    useContext(ProcessContext);

  const { currentContainer } = useContext(AuthContext);

  const { selection, Sidebar, isSelecting } = useContext(SelectableContext);

  const { loading } = useContext(UIContext);

  const [showContinueButton, setShowContinueButton] = useState(false);

  const { confirm, ConfirmDialog } = useConfirm();

  const load = async () => {
    await loadOccupancy(additionalProcessInformation?.destinationEntity, currentContainer?.id);
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (selection.length > 0 && !isSelecting) {

      setShowContinueButton(true);

    } else if (isSelecting) {
      setShowContinueButton(false);
    }
  }, [selection, isSelecting]);

  const proceedToSlotSelectionConfirmation = async () => {
    try {
      if (additionalProcessInformation.count && selection.length * additionalProcessInformation.seedsPerPlug > additionalProcessInformation.count) {
        let result = await confirm({
          title: (
            <>
              <strong>There may be not enough seeds to seed in selected slots. Do you want to proceed?</strong>
            </>
          ),
          message: 'Are you sure?',
          confirmText: 'Continue',
          confirmColor: 'success',
          cancelColor: 'muted',
          centered: true,
        });

        if (!result) return;
        await continueProcess(activeProcessId, selection);
        navigate(forwardTo);
      }
      else {
        await continueProcess(activeProcessId, selection);
        navigate(forwardTo);
      }

    } catch (error) {
      console.error(error);
    }
  };

  const reversedArray = [...occupancyArray].reverse();

  return loading ? (
    <Loading fullScreen />
  ) : (
    <>
      <ConfirmDialog />
      <BackButton disabled={false} navigateTo={backwardTo} />
      {Sidebar}
      <Tray>
        {occupancyArray &&
          occupancyArray.length > 0 &&
          reversedArray.map((unit) => (
            <SeedSlotSelectable
              key={unit.index}
              processName='planting'
              displayName={!unit.isAvailable ? unit.display : ''}
              index={unit.index}
              selectable={unit.isAvailable}
              isMultiharvestable={unit.isMultiharvestable}
              seedTypeIsMultiharvestable={additionalProcessInformation.isMultiharvestable}
            />
          ))}
      </Tray>
      {showContinueButton && (
        <>
          <StatusBarButton
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            clickHandler={proceedToSlotSelectionConfirmation}
            statusSlot={5}
          />
        </>
      )}
    </>
  );
};

export default EmptyTraySlotSelector;