import ConfirmDeleteModal from "./ConfirmDeleteModal";
import SeedTypeManagementContext from '../../../contexts/seed-type-management/SeedTypeManagementContext';
import AuthContext from "../../../contexts/auth/AuthContext";
import { useContext, useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import InputField from "../../layout/InputField";
import LabeledInputField from "../../layout/LabeledInputField";
import useFarmerAppPolicy from "../../../customHooks/useFarmerAppPolicy";
import { TENANT_ADMIN } from "../../../constants/roles/roles";
import Button from "../../layout/Button";
import Loading from '../../ui-elements/Loading';
import useConfirm from "../../confirmation/useConfirm";

const Card = ({ seedType }) => {
    const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
    const [editEnabled, setEditEnabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const { updateSeedType, deleteSeedType } = useContext(SeedTypeManagementContext);
    const { currentTenant, currentContainer, loadAndSetAccessibleSeedTypesAsync } = useContext(AuthContext);
    const tenantAdminPolicy = useFarmerAppPolicy([TENANT_ADMIN]);
    const { confirm, ConfirmDialog } = useConfirm();
    const navigate = useNavigate();

    const initialState = {
        name: {
            value: seedType.name ?? '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: !!seedType?.name,
            placeholder: 'Seed Type name ...',
        },
        scientificName: {
            value: seedType.scientificName ?? '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: !!seedType?.scientificName,
            placeholder: 'Scientific name ...',
        },
        commonName: {
            value: seedType.commonName ?? '',
            touched: false,
            validate: function (text) {
                return text.length >= 3;
            },
            isValid: !!seedType?.commonName,
        },
        seedsPerPlug: {
            value: seedType.seedsPerPlug ?? '',
            touched: false,
            validate: function (str) {
                const num = parseInt(str, 10);
                return num >= 1;
            },
            isValid: !!seedType?.seedsPerPlug,
        }
    };

    const [seedTypeState, setSeedTypeState] = useState(initialState);

    useEffect(() => {
        if (seedType) {
            const seedTypeStateInit = Object.entries(seedType).reduce((acc, [key, value]) => {
                if (key in seedTypeState === false) return acc;

                return {
                    ...acc,
                    [key]: { ...acc[key], value, isValid: acc[key]?.validate(value) },
                };
            }, initialState);

            setSeedTypeState(seedTypeStateInit);
        }
    }, [seedType]);

    const onChange = (event) => {
        event.persist();

        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setSeedTypeState((prev) => ({
            ...prev,
            [key]: {
                ...seedTypeState[key],
                value,
                isValid: seedTypeState[key].validate(value),
                touched: true,
            },
        }));
    };

    const createRequestBody = () => {
        const updatedSeedTypeProperties = Object.entries(seedTypeState).reduce((acc, [key, valueObject]) => {
            return { ...acc, [key]: valueObject.value };
        }, {});
        const updatedSeedType = {
            ...seedType,
            ...updatedSeedTypeProperties,
        };

        return updatedSeedType;
    };

    const handleSave = async () => {
        if (!isStateValid || !isStateTouched) {
            return;
        }

        let result = await confirm({
            title: (
                <>
                    <strong>ALTER SEED TYPE</strong>
                </>
            ),
            message:
                'Changing seed type properties will alter the data of every plant ever created with this seed type. Are you sure you want to continue with your changes?',
            confirmText: 'Are you sure?',
            confirmColor: 'success',
            cancelColor: 'muted',
            centered: true,
        });

        if (result) {
            setLoading(true);
            await updateSeedType(createRequestBody());
            await loadAndSetAccessibleSeedTypesAsync(currentTenant, currentContainer, false, 'seedVariantTypes');
            setEditEnabled(false);
            setLoading(false);
        }
    };

    const isStateValid = Object.values(seedTypeState)
        .map((entry) => entry.isValid)
        .every((item) => !!item);

    const isStateTouched = Object.values(seedTypeState).map(entry => entry.touched).some((item) => !!item);

    const handleDelete = async () => {
        setShowConfirmDeleteModal(true);
    };

    const onCancelConfirmEdit = () => {
        setShowConfirmDeleteModal(false);
    };

    const confirmDelete = async () => {
        setLoading(true);
        await deleteSeedType(seedType);
        await loadAndSetAccessibleSeedTypesAsync(currentTenant, currentContainer, false, 'seedVariantTypes');
        setShowConfirmDeleteModal(false);
        setLoading(false);
    };

    const handleVariants = () => {
        navigate(`/management/seed-types/${seedType.id}/seed-variant-types/list`);
    }

    const toggleEdit = () => {
        setEditEnabled(!editEnabled);
        setSeedTypeState(initialState);
    };

    return loading ? <Loading fullScreen={true} /> : <>
    <ConfirmDialog />
        <ConfirmDeleteModal show={showConfirmDeleteModal} onCancel={onCancelConfirmEdit} onConfirm={confirmDelete} />
        <div className="seed-type-card">
            <div className="seed-type-card-header">
                <div className="seed-type-name-settings">
                    <InputField
                        inputName={'name'}
                        value={seedTypeState.name.value}
                        isValid={seedTypeState.name.isValid}
                        touched={seedTypeState.name.touched}
                        inputType={'text'}
                        onChange={onChange}
                        placeholder={'Name'}
                        disabled={editEnabled === false}
                        errorMessage={'Minimum 3 characters.'}
                    />
                </div>
                <div className="seed-type-card-number">
                    N° {seedType.number}
                </div>
                <div className="seed-type-scientificName-settings">
                    <InputField
                        inputName={'scientificName'}
                        value={seedTypeState.scientificName.value}
                        isValid={seedTypeState.scientificName.isValid}
                        touched={seedTypeState.scientificName.touched}
                        inputType={'text'}
                        onChange={onChange}
                        placeholder={'Scientific Name'}
                        disabled={editEnabled === false}
                        errorMessage={'Minimum 3 characters.'}
                    />
                </div>
            </div>
            <div className="seed-type-commonName-settings">
                <LabeledInputField
                    labelText={'Common Name'}
                    inputName={'commonName'}
                    value={seedTypeState.commonName.value}
                    isValid={seedTypeState.commonName.isValid}
                    touched={seedTypeState.commonName.touched}
                    inputType={'text'}
                    onChange={onChange}
                    placeholder={'Common name'}
                    disabled={editEnabled === false}
                    errorMessage={'Minimum 3 characters.'}
                />
            </div>
            <div className="seed-type-seedsPerPlug-settings">
                <LabeledInputField
                    labelText={'Seeds per Plug'}
                    inputName={'seedsPerPlug'}
                    value={seedTypeState.seedsPerPlug.value}
                    isValid={seedTypeState.seedsPerPlug.isValid}
                    touched={seedTypeState.seedsPerPlug.touched}
                    inputType={'number'}
                    onChange={onChange}
                    placeholder={'Seeds per Plug'}
                    disabled={editEnabled === false}
                    errorMessage={'Has to be 1 or higher.'}
                />
            </div>
            {!editEnabled
                ? <>
                    <Button className="seed-type-card-delete seed-type-card-button" label="Delete" clickHandler={handleDelete} disabled={tenantAdminPolicy.isForbidden()} />
                    <Button className="seed-type-card-variants seed-type-card-button" label="Variants" clickHandler={handleVariants} />
                    <Button className="seed-type-card-edit seed-type-card-button" label="Edit" clickHandler={toggleEdit} disabled={tenantAdminPolicy.isForbidden()} />
                </>
                :
                <>
                    <div className="seed-type-card-cancel seed-type-card-button" onClick={toggleEdit}>
                        Cancel
                    </div>
                    <div className={(!isStateValid || !isStateTouched ? "disabled " : "") + "seed-type-card-save seed-type-card-button"} onClick={handleSave}>
                        Save
                    </div>
                </>
            }
        </div>
    </>
};

export default Card;