export const awsconfig = {
  "Auth": {
    "region": import.meta.env.VITE_REGION,
    "userPoolId": import.meta.env.VITE_COGNITO_USERPOOL_ID,
    "userPoolWebClientId": import.meta.env.VITE_COGNITO_USERPOOL_CLIENTID,
    "mandatorySignIn": true,
    "redirectSignIn": import.meta.env.VITE_CALLBACK_URL,
    "redirectSignOut": import.meta.env.VITE_CALLBACK_URL,
    "oauth": {
      "domain": import.meta.env.VITE_COGNITO_DOMAIN,
      "scope": ['email', 'openid', 'profile'],
      "redirectSignIn": import.meta.env.VITE_CALLBACK_URL,
      "redirectSignOut": import.meta.env.VITE_CALLBACK_URL,
      "responseType": 'code'
    }
  }
}
